import type { AEMThemeType } from "../../../../themes/common";
import { parseHexToRGB } from "../../../../utils";

export type ColorType = keyof typeof colors;

// TODO: Clean up deprecated colours after revamp is complete
const colors = {
    black: "#101010", // Used for all shapes and graphical elements. Not used for text.
    midnight: "#1A1A1F", // Used for all text styles. Rarely used for shapes/graphical elements.
    steel: "#363840", // Use: Subheader text (cards)
    platinumDark: "#52565E", // Used for labels and secondary information
    brushedSteel: "#6C6F76",
    disabledLight: "#6C6F76", // Used for labels and secondary information
    disabledDark: "#969CA3",
    platinumMedium: "#B0B7C0",
    platinumLight: "#C7CFD7", // Used for dividers
    titanium: "#969CA3",
    silver: "#E2E8EF", // Use: Card background colour
    mist: "#F4F6FB",
    white: "#FFFFFF", // Used for text and to give breathing space.
    azure: "#20365C", // Only used to hightlight EV engine labels
    azureHover: "#4D5E7D", // Only used to hightlight EV engine labels
    skyBlue: "#005FB6", // Only used to hightlight EV engine labels
    electricBlue: "#0072F0", // Only used to hightlight EV engine labels
    outline: "#0072F0", // Form elements outline colour
    validation: "#4CAF50", // Validation
    error: "#B12A31", // Error
    successLight: "#D2F4C9",
    successDark: "#0E5911",
    danger: "#FAD2DB",
    dangerDark: "#960101",
    /** @deprecated since lexus revamp */
    lightest: "#FFF",
    /** @deprecated since lexus revamp */
    //black: "#000000",
    /** @deprecated since lexus revamp */
    dark: "#191919",
    /** @deprecated since lexus revamp */
    darker: "#3C3C3C",
    /** @deprecated since lexus revamp */
    deepGrey: "#545A5D",
    /** @deprecated since lexus revamp */
    bodyBlack: "#474747",
    /** @deprecated since lexus revamp */
    transparentDark: `rgba(${parseHexToRGB("#000000").rgb}, 0.8)`,
    /** @deprecated since lexus revamp */
    grey1: "#EEEDEC",
    /** @deprecated since lexus revamp */
    grey2: "#D6D4D2",
    /** @deprecated since lexus revamp */
    grey3: "#777777",
    /** @deprecated since lexus revamp */
    grey4: "#191919",
    /** @deprecated since lexus revamp */
    grey6: "#A8AAAC",
    /** @deprecated since lexus revamp */
    grey7: "#6C7073",
    //midnight: "#191A1E",
    /** @deprecated since lexus revamp */
    hybrid: "#00A0F0",
    /** @deprecated since lexus revamp */
    fuelCell: "#3F51B5",
    /** @deprecated since lexus revamp */
    success: "#4CAF50",
    /** @deprecated since lexus revamp */
    red: "#E50000",
    grey: "#B0B8C0",
    greyLight: "#E4E4E4",
};

export type IconSizeType = "xxs" | "xs" | "sm" | "md" | "lg" | "xl";

const iconSize = {
    xxs: ".8rem",
    xs: "1.2rem",
    sm: "1.4rem",
    md: "1.6rem",
    lg: "1.8rem",
    xl: "2rem",
};

// TODO: remove light font after revamp is complete
const fonts = {
    /** @deprecated since lexus revamp. There's no light font in Lexus */
    light: '"Nobel book",Helvetica,Arial,Roboto,sans-serif',
    book: '"Nobel Book",Helvetica,Arial,Roboto,sans-serif',
    regular: '"Nobel Regular",Helvetica,Arial,Roboto,sans-serif',
    bold: '"Nobel Bold",Helvetica,Arial,Roboto,sans-serif',
};

const spacer = 32;

/** @description All spacing (padding, margins) need to be a multiple of 8px */
const space = [0, 8, 16, 24, 32, 40, 80, 120];

const breakpoints = {
    xs: "0",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1600px",
};

const zIndex = {
    gradesNav: 5,
    dropdown: 1000,
    sticky: 1020,
    fixed: 1030,
    modalBackdrop: 1040,
    modal: 1050,
    overlay: 1055, // pageoverlayer inpage
    pageoverlayerExpansion: 1056,
    mobileBasket: 1060,
    stickyHeader: 1059,
    popover: 1060,
    materialbox: 1060,
    tooltip: 1070,
    mobileFooter: 1058,
};

export type ZIndexType = keyof typeof zIndex;

export const styledSystemTheme: AEMThemeType = {
    isRTL: false,
    noContainerSpacing: false,
    breakpoints,
    space,
};

export const theme = {
    colors,
    fonts,
    iconSize,
    spacer,
    space,
    zIndex,
} as const;
