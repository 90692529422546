import type { FC } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import * as Styles from "./styles/NoResultStyles";
import { useCarFilterLabel } from "../../../utils/constants/labels";
import type { CarFilterDispatchType } from "../../../redux/store";
import { useCarFilterSelector } from "../../../redux/store";
import { clearAllFilters } from "../../../utils/filters";
import ButtonTertiary from "../../../../../common-deprecated/components/aem/toyota/ButtonTertiary";

type NoResultsPropsType = {
    showMoreFilters: boolean;
};

const NoResults: FC<NoResultsPropsType> = ({ showMoreFilters }) => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const carFilters = useCarFilterSelector((state) => state.carFilters);
    const clearAllFiltersHandler = (): void => {
        clearAllFilters(dispatch, carFilters);
    };

    const titleLabel = useCarFilterLabel("carFilterNoResultsTitle");
    const descriptionLabel = useCarFilterLabel("carFilterNoResultsDescription");
    const buttonLabel = useCarFilterLabel("carFilterClearAllFilters");

    return (
        <Styles.Wrapper showMoreFilters={showMoreFilters}>
            <Styles.NoResultTextWrapper>
                <Styles.NoResultMessage>{titleLabel}</Styles.NoResultMessage>
                <Styles.NoResultDescription>{descriptionLabel}</Styles.NoResultDescription>
            </Styles.NoResultTextWrapper>

            <ButtonTertiary onClick={clearAllFiltersHandler}>{buttonLabel}</ButtonTertiary>
        </Styles.Wrapper>
    );
};

export default NoResults;
