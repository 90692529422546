import type { CommonSettingsType } from "../settings/fetchCommonSettings";
import { getAPI, hasAlternativeEcoSpecFlow } from "../settings/utils/commonSettingUtils";
import type { FetchSpecsEquipmentDataType } from "../server/data/FetchSpecifications";
import {
    EFFICIENCY_CLASS,
    EFFICIENCY_CLASS_EMPTY_BATTERY_TMEF,
    EFFICIENCY_CLASS_LEXUS,
    EFFICIENCY_CLASS_TMEF,
} from "../constants";
import { addTokenDebugVariablesToUrl } from "../../new-cars/shared-logic/utils/typedUtils";
import { EcoTagType } from "../types/CommonTypes";
import type { EngineMotorizationTexusType } from "../types/TexusTypes";
import { PriceCalculationServiceEndpoint } from "../types/SpecTypes";

export const fetchLeasingSpecs = async (
    commonSettings: CommonSettingsType,
    modelId: string,
    carId: string,
    carShortId: number,
    modelCode: string,
): Promise<FetchSpecsEquipmentDataType> => {
    const specsApi = addTokenDebugVariablesToUrl(commonSettings, getAPI(commonSettings, "specs"));

    const body = {
        model: modelId,
        car: carId,
        carShortId,
        modelCode,
    };

    const specsResult = await fetch(specsApi, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
    });

    if (specsResult.status === 200) {
        return specsResult.json();
    }
    throw new Error(`Request to ${specsApi} failed with status ${specsResult.status}`);
};

export const isEcoSpec = <T extends Partial<{ InternalCode: string; internalCode: string; code: string }>>(
    spec: T,
): boolean => {
    const internalCode =
        spec.InternalCode?.toUpperCase() || spec.internalCode?.toUpperCase() || spec.code?.toUpperCase();
    return (
        internalCode === EFFICIENCY_CLASS_TMEF ||
        internalCode === EFFICIENCY_CLASS ||
        internalCode === EFFICIENCY_CLASS_LEXUS ||
        internalCode === EFFICIENCY_CLASS_EMPTY_BATTERY_TMEF
    );
};

export const filterEcoSpecs = <T extends Partial<{ InternalCode: string; internalCode: string; code: string }>>(
    specs: T[],
): T[] => {
    return specs.filter((spec) => !isEcoSpec<T>(spec));
};

const getEngineType = (engine: EngineMotorizationTexusType): EcoTagType | undefined => {
    if (engine.Type.FuelCell) return EcoTagType.FuelCell;
    if (engine.PlugIn) return EcoTagType.Plugin;
    if (engine.Type.Hybrid) return EcoTagType.Hybrid; // both fuelCell and plugin can be hybrids they should be checked first
    if (engine.Type.Electric) return EcoTagType.Electric;
    return undefined;
};

/**
 * TDG Logic OR-10879
 * When a hybrid/plugin vehicle share certain specs, however they don't wish to show specific specs if plugin/hybrid since this would confuse customers
 */
export const filterEcoSpecsAlternateFlow = <
    T extends Partial<{ InternalCode: string; code: string; internalCode: string }>,
>(
    commonSettings: CommonSettingsType,
    specs: T[],
    ecoTagType?: EcoTagType,
    engine?: EngineMotorizationTexusType,
): T[] => {
    const alternateFlow = hasAlternativeEcoSpecFlow(commonSettings);
    if (!(ecoTagType || engine) || !alternateFlow) return specs;
    const ecoTag = ecoTagType || (engine && getEngineType(engine));

    const hasMultipleCo2Specs =
        specs.filter((spec) => {
            const internalCode =
                spec.InternalCode?.toLowerCase() || spec.internalCode?.toLowerCase() || spec.code?.toLowerCase() || "";
            return (
                internalCode.toLowerCase() === "co2combinedwltp" ||
                internalCode.toLowerCase() === "co2combinedcswltp" ||
                internalCode.toLowerCase() === "co2combinedufweightedwltp"
            );
        }).length > 1;
    const hasMultipleFuelSpecs =
        specs.filter((spec) => {
            const internalCode =
                spec.InternalCode?.toLowerCase() || spec.internalCode?.toLowerCase() || spec.code?.toLowerCase() || "";
            return (
                internalCode.toLowerCase() === "fuelcombinedwltp" || internalCode.toLowerCase() === "fuelcombinedcswltp"
            );
        }).length > 1;
    switch (ecoTag) {
        case EcoTagType.Plugin:
            return specs.filter((spec) => {
                const internalCode =
                    spec.InternalCode?.toLowerCase() ||
                    spec.internalCode?.toLowerCase() ||
                    spec.code?.toLowerCase() ||
                    "";
                return (
                    (!hasMultipleCo2Specs || internalCode.toLowerCase() !== "co2combinedcswltp") &&
                    (!hasMultipleFuelSpecs || internalCode.toLowerCase() !== "fuelcombinedwltp")
                );
            });
        case EcoTagType.Hybrid:
            return specs.filter((spec) => {
                const internalCode =
                    spec.InternalCode?.toLowerCase() ||
                    spec.internalCode?.toLowerCase() ||
                    spec.code?.toLowerCase() ||
                    "";
                return !hasMultipleFuelSpecs || internalCode.toLowerCase() !== "fuelcombinedcswltp";
            });
        case EcoTagType.Electric:
            return specs.filter((spec) => {
                const internalCode =
                    spec.InternalCode?.toLowerCase() ||
                    spec.internalCode?.toLowerCase() ||
                    spec.code?.toLowerCase() ||
                    "";
                /**
                 * Hide normal fuelcombined for electric
                 * This is only relevant for Proace since the model has electric
                 * & ICE powertrains under a single grade
                 * because specs are managed on grade level
                 */
                return internalCode.toLowerCase() !== "fuelcombinednormalwltp";
            });
        default:
            return specs;
    }
};

export const valuesToRange = (values: (string | number)[]): string => {
    const processedValues = values
        .map((value) => (typeof value === "number" ? value : parseFloat(value)))
        .filter((val) => !Number.isNaN(val));
    return processedValues.length > 1 && !processedValues.every((val) => val === processedValues[0])
        ? `${Math.min(...processedValues)} - ${Math.max(...processedValues)}`
        : `${processedValues[0] || ""}`;
};

export const getPriceCalculationServiceEndpointType = (
    priceCalculationService: string,
): PriceCalculationServiceEndpoint => {
    return priceCalculationService.includes(PriceCalculationServiceEndpoint.Prices)
        ? PriceCalculationServiceEndpoint.Prices
        : PriceCalculationServiceEndpoint.Price;
};

// Returns a trimmed string with the value and (optional) unit
export const formatValueUnit = (value: string, unit: string | undefined): string => {
    return `${value} ${unit || ""}`.trim();
};
