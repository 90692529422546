import type { TypedUseSelectorHook } from "react-redux";
import { useSelector } from "react-redux";
import type { Dispatch } from "redux";
import type { CommonSettingsActionsType } from "./actions/CommonSettingsActions";
import type { ModalReducerType } from "./reducers/ModalReducer";
import type { CommonSettingsType } from "../settings/fetchCommonSettings";
import type { UtilStateType } from "./reducers/UtilReducer";
import type { ModalActionsType } from "./actions/ModalActions";
import type { UtilActionsType } from "./actions/UtilActions";
import type { NotificationReducerType } from "./reducers/NotificationReducer";
import type { NotificationActionsType } from "./actions/NotificationActions";

export type CommonActionsType =
    | CommonSettingsActionsType
    | UtilActionsType
    | ModalActionsType
    | NotificationActionsType;

export type CommonDispatchType = Dispatch<CommonActionsType>;

export type CommonReducerType = {
    commonSettings: CommonSettingsType;
    utils: UtilStateType;
    modal: ModalReducerType;
    notification?: NotificationReducerType;
};

export const useCommonSelector: TypedUseSelectorHook<CommonReducerType> = useSelector;
