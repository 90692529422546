import type { ModalMapType } from "../../../../../../common-deprecated/types/CommonTypes";
import IntegratedUscFormModal from "../../../../../shared-components/toyota/floating-elements/modals/integrated-modal/IntegratedUscFormModal";
import type { CarFilterModalIdsType } from "../../../../utils/modalConstants";
import {
    MODAL_FILTER_CAR_FILTERS,
    MODAL_FILTER_CONFIG_HELPER,
    MODAL_FILTER_VEHICLE_SALE_TYPE,
    MODAL_FILTER_SORT_FILTERS,
} from "../../../../utils/modalConstants";
import ConfigHelperModal from "./ConfigHelperModal";
import { MODAL_USC_FORM } from "../../../../../shared-logic/utils/integrated-modal/integratedModalConstants";
import type { SharedUscModalIdsType } from "../../../../../shared-logic/utils/modalConstants";
import { MODAL_USC_INJECTION, MODAL_VEHICLE_FOR_SALE_ID } from "../../../../../shared-logic/utils/modalConstants";
import VehicleForSaleIdModal from "../../../../../shared-components/toyota/floating-elements/modals/vehicle-for-sale-id-modal/VehicleForSaleIdModal";
import CarFilterVehicleSaleTypeModal from "./CarFilterVehicleSaleTypeModal";
import CarFiltersModal from "./CarFiltersModal";
import SortFiltersModal from "./SortFiltersModal";
import UscInjectionModal from "./UscInjectionModal";
import { uscToyotaModalMap } from "../../../../../shared-components/toyota/floating-elements/modals/modals";

export const aemToyotaCarFilterModalMap: ModalMapType<CarFilterModalIdsType & SharedUscModalIdsType> = {
    ...uscToyotaModalMap,
    [MODAL_FILTER_CONFIG_HELPER]: ConfigHelperModal,
    [MODAL_USC_FORM]: IntegratedUscFormModal,
    [MODAL_VEHICLE_FOR_SALE_ID]: VehicleForSaleIdModal,
    [MODAL_FILTER_VEHICLE_SALE_TYPE]: CarFilterVehicleSaleTypeModal,
    [MODAL_FILTER_CAR_FILTERS]: CarFiltersModal,
    [MODAL_FILTER_SORT_FILTERS]: SortFiltersModal,
    [MODAL_USC_INJECTION]: UscInjectionModal,
};
