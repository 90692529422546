import type { Dispatch, ReducersMapObject, Store } from "redux";
import type { TypedUseSelectorHook } from "react-redux";
import { useSelector } from "react-redux";
import type { CommonActionsType, CommonReducerType } from "../../../common-deprecated/redux/commonStore";
import type { CarFiltersReducerType } from "./reducers/CarFiltersReducer";
import carFiltersReducer from "./reducers/CarFiltersReducer";
import modalReducer from "../../../common-deprecated/redux/reducers/ModalReducer";
import { getInitStoreByReducers } from "../../../common-deprecated/redux/storeHelpers";
import type { CarFiltersActionsType } from "./actions/CarFiltersActions";
import type { CreateLogicType } from "../../../common-deprecated/redux/typeHelpers";
import filterLogic from "./logic/filterLogic";
import usedCarResultLogic from "./logic/carResultLogic";
import utilReducer from "../../../common-deprecated/redux/reducers/UtilReducer";
import type { CarResultsReducerType } from "./reducers/CarResultsReducer";
import carResultsReducer from "./reducers/CarResultsReducer";
import type { CarResultsActionsType } from "./actions/CarResultsActions";
import CommonSettingsReducer from "../../../common-deprecated/redux/reducers/CommonSettingsReducer";
import type { CarFilterSettingsType } from "./reducers/CarFilterSettingsReducer";
import CarFilterSettingsReducer from "./reducers/CarFilterSettingsReducer";
import type { CarFilterSettingsActionsType } from "./actions/CarFilterSettingsActions";
import savedCarLogic from "./logic/savedCarLogic";
import type { RetailerSettingsActionsType } from "../../../shared-logic/features/retailer/redux/actions/RetailerSettingsActions";
import type { RetailerSettingsType } from "../../../shared-logic/features/retailer/redux/reducers/RetailerSettingsReducer";
import RetailerSettingsReducer from "../../../shared-logic/features/retailer/redux/reducers/RetailerSettingsReducer";
import type { UsedCarCompareExternalDataReducerType } from "../../../common-deprecated/features/compare/redux/reducers/UsedCarCompareExternalDataReducer";
import UsedCarCompareExternalDataReducer from "../../../common-deprecated/features/compare/redux/reducers/UsedCarCompareExternalDataReducer";

export type CarFilterStateType = CommonReducerType & {
    carFilters: CarFiltersReducerType;
    carResults: CarResultsReducerType;
    retailerSettings: RetailerSettingsType;
    carFilterSettings: CarFilterSettingsType;
    usedCarCompareExternalData: UsedCarCompareExternalDataReducerType;
};

export type CarFilterActionsType =
    | CommonActionsType
    | CarFiltersActionsType
    | RetailerSettingsActionsType
    | CarFilterSettingsActionsType
    | CarResultsActionsType;

export type CarFilterDispatchType = Dispatch<CarFilterActionsType>;

export type CarFilterLogicType<ActionsType extends CarFilterActionsType> = CreateLogicType<
    ActionsType,
    CarFilterStateType,
    CarFilterDispatchType
>;

export type CarFilterStoreType = Store<CarFilterStateType, CarFilterActionsType>;

export const useCarFilterSelector: TypedUseSelectorHook<CarFilterStateType> = useSelector;

export const carFilterReducers: ReducersMapObject<CarFilterStateType, any> = {
    carFilters: carFiltersReducer,
    carResults: carResultsReducer,
    modal: modalReducer,
    commonSettings: CommonSettingsReducer,
    carFilterSettings: CarFilterSettingsReducer,
    retailerSettings: RetailerSettingsReducer,
    usedCarCompareExternalData: UsedCarCompareExternalDataReducer,
    utils: utilReducer,
};

export const carFilterClientLogic = [...filterLogic, ...usedCarResultLogic, ...savedCarLogic];

export const carFilterServerStore = getInitStoreByReducers(carFilterReducers, []);
export const carFilterClientStore = getInitStoreByReducers(carFilterReducers, carFilterClientLogic);
