import type React from "react";
import { useEffect, useRef, useState } from "react";

type UseCarResultCardReturnType = {
    moreSpecsRef: React.MutableRefObject<HTMLDivElement | null>;
    toggleOpen: () => void;
    moreSpecsExpanded: boolean;
};

const useCarResultCard = (isCarFilterResults?: boolean): UseCarResultCardReturnType => {
    const [isOpen, setIsOpen] = useState(false);
    const moreSpecsRef = useRef<HTMLDivElement | null>(null);

    const toggleOpen = (): void => setIsOpen(!isOpen);

    // Listen for clicks outside of the expanded dialog, as well as the escape key
    useEffect(() => {
        const handleClickOutside = (evt: MouseEvent | KeyboardEvent): void => {
            if (evt instanceof MouseEvent && !moreSpecsRef.current?.contains(evt.target as Node)) {
                // Clicked outside of the expanded dialog
                setIsOpen(false);
            } else if (evt instanceof KeyboardEvent && evt.key === "Escape") {
                // Pressed the escape key
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        document.addEventListener("keydown", handleClickOutside);

        return (): void => {
            document.removeEventListener("mousedown", handleClickOutside);
            document.removeEventListener("keydown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isCarFilterResults) return; // Logic below should only happen on the Car Filter page, not for similar cars.

        const element = moreSpecsRef?.current;
        if (isOpen && element) {
            // Scroll the element into view
            try {
                element.scrollIntoView({ behavior: "smooth", block: "center" });
            } catch (ignored) {
                element.scrollIntoView(); // retailer specific fallback for old browser support
            }

            // Lock the scroll
            document.body.style.overflow = "hidden";

            // eslint-disable-next-line consistent-return
            return () => {
                // Unlock the scroll when the component unmounts
                document.body.style.overflow = "";
            };
        }
    }, [isOpen]);

    return { moreSpecsRef, toggleOpen, moreSpecsExpanded: isOpen };
};

export default useCarResultCard;
