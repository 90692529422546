import type { UtilActionsType } from "../actions/UtilActions";
import {
    ADD_ERROR_LOG,
    ADD_REQUEST_LOG,
    BUY_WAIT_FOR_ACTIONS,
    ExteriorSpin3DState,
    HIDE_OVERLAY_LOADER,
    SET_3D_EXTERIOR_POSITION,
    SET_3D_INTERIOR_POSITION,
    SET_ACTIVE_EQUIPMENT_ITEM_CODE,
    SET_ACTIVE_PERSONALISATION,
    SET_DOM_TARGET,
    SET_EXTERIOR_SPIN_3D,
    SET_EXTERIOR_SPIN_FRAME,
    SET_FILTER_EQUIPMENT,
    SET_FINANCE_OPTION,
    SET_HLIST_PAGE,
    SET_IS_BUILD,
    SET_MODEL_FILTERS_LOADED,
    SET_SCROLL_TO_FINANCE,
    SHOW_MOBILE_BASKET,
    SHOW_OVERLAY_LOADER,
    SHOW_TOP_SPIN_VIEW,
    SHOW_WINTERWHEEL,
    START_EXTERIOR_SPIN_PRELOAD,
} from "../actions/UtilActions";
import type { FilterPositionType } from "../../utils";
import { FILTER_POSITION_TOP } from "../../utils";
import type { FinanceInsuranceActionsType } from "../../../new-cars/original/buildandbuy/redux/actions/FinanceInsuranceActions";
import {
    PREPARE_CALCULATOR,
    RESET_CALCULATOR,
} from "../../../new-cars/original/buildandbuy/redux/actions/FinanceInsuranceActions";
import type { ErrorLogType, FinanceOptionType, PersonalizationType } from "../../types/CommonTypes";
import type { RequestLogType } from "../../utils/backendFetch";

export type UtilStateType = {
    domTarget: string;
    financeOption: FinanceOptionType;
    activePersonalization: PersonalizationType;
    showOverlayLoader: boolean;
    showOverlayDisclaimer: boolean;
    showMobileBasket: boolean;
    analyticsLoaded: boolean;
    waitingForAnalytics: boolean;
    homepageFilterPosition: FilterPositionType;
    calculatorReady: boolean;
    waitingForBuyActions: boolean;
    scrollToFinance: boolean;
    isBuild: boolean; // This seems like a legacy setting. Can probably be removed.
    showWinterWheel: boolean;
    // Used to show the top view mode (which shows a convertible car with the roof collapsed)
    showTopSpinView: boolean;
    // This is a helper during the fetch of modelFilter data.
    modelFiltersLoaded: boolean;
    requestLog: RequestLogType[];
    errorLog: ErrorLogType[];
    salesmanOverrides: {
        loadExteriorSpinCacheKey: string;
        currentExteriorSpinFrame: { key: string; frame: number };
        current3DInteriorPosition: { x: number | null; y: number | null; z: number | null };
        currentHList: { className: string; page: number };
        current3DExteriorPosition: { x: number | null; y: number | null; z: number | null };
    };
    activeEquipmentCode: string;
    exteriorSpin3D: ExteriorSpin3DState;
    filterEquipment: string;
    expandedGrade: string;
};

export const initialState: UtilStateType = {
    domTarget: "",
    financeOption: "cash",
    activePersonalization: "colour",
    showOverlayLoader: false,
    showOverlayDisclaimer: true,
    showMobileBasket: false,
    analyticsLoaded: false,
    waitingForAnalytics: false,

    homepageFilterPosition: FILTER_POSITION_TOP,

    calculatorReady: false,

    waitingForBuyActions: false,
    scrollToFinance: false,
    isBuild: false,
    showWinterWheel: false,
    showTopSpinView: false,
    modelFiltersLoaded: false,
    requestLog: [],
    errorLog: [],
    salesmanOverrides: {
        loadExteriorSpinCacheKey: "",
        currentExteriorSpinFrame: { key: "", frame: -1 },
        current3DInteriorPosition: { x: null, y: null, z: null },
        currentHList: { className: "", page: -1 },
        current3DExteriorPosition: { x: null, y: null, z: null },
    },
    activeEquipmentCode: "",
    exteriorSpin3D: ExteriorSpin3DState.Hidden,
    filterEquipment: "",
    expandedGrade: "",
};

export default (
    state: UtilStateType = initialState,
    action: UtilActionsType | FinanceInsuranceActionsType,
): UtilStateType => {
    switch (action.type) {
        case SET_DOM_TARGET:
            return { ...state, domTarget: action.target };

        case SET_FINANCE_OPTION:
            return { ...state, financeOption: action.option };

        case SET_ACTIVE_PERSONALISATION:
            return { ...state, activePersonalization: action.personalization };

        case SET_MODEL_FILTERS_LOADED:
            return { ...state, modelFiltersLoaded: action.loaded };

        case SHOW_OVERLAY_LOADER: {
            return { ...state, showOverlayLoader: true };
        }

        case HIDE_OVERLAY_LOADER: {
            return { ...state, showOverlayLoader: false };
        }

        case PREPARE_CALCULATOR:
            return { ...state, calculatorReady: true };

        case RESET_CALCULATOR:
            return { ...state, calculatorReady: false };

        case BUY_WAIT_FOR_ACTIONS:
            return { ...state, waitingForBuyActions: action.waiting };

        case SET_SCROLL_TO_FINANCE:
            return { ...state, scrollToFinance: action.scroll };

        case SET_IS_BUILD:
            return { ...state, isBuild: action.build };

        case ADD_REQUEST_LOG:
            return { ...state, requestLog: [...state.requestLog, ...action.requestLog] };

        case ADD_ERROR_LOG:
            return { ...state, errorLog: [...state.errorLog, ...action.errorLog] };

        case SHOW_MOBILE_BASKET:
            return { ...state, showMobileBasket: action.show };

        case SHOW_WINTERWHEEL:
            return { ...state, showWinterWheel: action.show };

        case SHOW_TOP_SPIN_VIEW:
            return { ...state, showTopSpinView: action.show };

        case START_EXTERIOR_SPIN_PRELOAD:
            return {
                ...state,
                salesmanOverrides: { ...state.salesmanOverrides, loadExteriorSpinCacheKey: action.key },
            };

        case SET_EXTERIOR_SPIN_FRAME:
            return {
                ...state,
                salesmanOverrides: {
                    ...state.salesmanOverrides,
                    currentExteriorSpinFrame: { key: action.key, frame: action.frame },
                },
            };

        case SET_3D_INTERIOR_POSITION:
            return {
                ...state,
                salesmanOverrides: {
                    ...state.salesmanOverrides,
                    current3DInteriorPosition: { x: action.x, y: action.y, z: action.z },
                },
            };

        case SET_3D_EXTERIOR_POSITION:
            return {
                ...state,
                salesmanOverrides: {
                    ...state.salesmanOverrides,
                    current3DExteriorPosition: { x: action.x, y: action.y, z: action.z },
                },
            };

        case SET_HLIST_PAGE:
            return {
                ...state,
                salesmanOverrides: {
                    ...state.salesmanOverrides,
                    currentHList: { className: action.className, page: action.page },
                },
            };

        case SET_ACTIVE_EQUIPMENT_ITEM_CODE:
            return {
                ...state,
                activeEquipmentCode: action.itemCode,
            };

        case SET_EXTERIOR_SPIN_3D:
            return {
                ...state,
                exteriorSpin3D: action.is3D,
            };
        case SET_FILTER_EQUIPMENT:
            return {
                ...state,
                filterEquipment: action.filterEquipment,
            };
        default:
            return state;
    }
};
