import React from "react";
import { shallowEqual, useDispatch } from "react-redux";
import type { CarFilterDispatchType } from "../../../../redux/store";
import { useCarFilterSelector } from "../../../../redux/store";
import { UsedCarFilterId } from "../../../../../shared-logic/types/UscCommonTypes";
import { getAemCarFilterTrackParamsSelector, trackAemMultipleChoiceFilter } from "../../../../utils/tracking";
import FilterCheckInput from "../../common/FilterCheckInput";
import { setSelectMultiFilter } from "../../../../redux/actions/CarFiltersActions";
import EfficiencyClassIcon from "../../../../../shared-components/toyota/efficiency-class/EfficiencyClassIcon";
import type { DfEfficiencyClassCode } from "../../../../../shared-logic/utils/dfConstants";
import { sortEfficiencyClasses } from "../../../../../shared-logic/utils/uscEcoUtils";
import * as Styles from "./styles/EfficiencyClassFilterStyles";

const EfficiencyClassFilter = (): JSX.Element | null => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const filter = useCarFilterSelector((state) => state.carFilters[UsedCarFilterId.EfficiencyClass]);
    const trackParams = useCarFilterSelector(
        getAemCarFilterTrackParamsSelector(UsedCarFilterId.EfficiencyClass),
        shallowEqual,
    );
    const ecoLabelpopup = useCarFilterSelector((state) => state.commonSettings.ecoLabelpopup);

    return (
        <>
            {sortEfficiencyClasses(filter).map((val) => {
                const color = ecoLabelpopup?.labels.find(({ letter }) => letter === val.id)?.color;

                return (
                    <Styles.EfficiencyClassFilter key={val.id}>
                        <FilterCheckInput
                            checked={val.selected}
                            label={val.label}
                            onChange={() => {
                                dispatch(
                                    setSelectMultiFilter([val.id], !val.selected, UsedCarFilterId.EfficiencyClass),
                                );
                                trackAemMultipleChoiceFilter(trackParams, filter.values, val.id);
                            }}
                            id={val.id}
                            resultCount={val.resultCount}
                        />
                        <EfficiencyClassIcon efficiencyClass={val.id as DfEfficiencyClassCode} color={color} />
                    </Styles.EfficiencyClassFilter>
                );
            })}
        </>
    );
};

export default EfficiencyClassFilter;
