import { createGlobalStyle, css } from "styled-components";
import { Colors, Dropshadows, Fonts, Spacers, ZIndex } from "./Variables.styles";
import { focusStyle } from "./utils/Accessibility.styles";

/* ⚠️ Be careful! This file overrides styles from the loaded Toyota stylesheet. */
const GlobalStyle = createGlobalStyle<{ isPdf?: boolean }>`

    /* Variables */
    :root {
        ${Fonts};
        ${Colors};
        ${Dropshadows};
        ${Spacers};
        ${ZIndex};
    }

    /* Reset Toyota CSS */
    /* To avoid  that these style will override also the styles from other components we scoped our component with an id. */
    #usc,
    .usc-modal-container {
        h3 {
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
            text-indent: 0;
        }

        li {
            margin-bottom: 0;
        }

        * {
            ${focusStyle};
        }
    }

    ${({ isPdf }) =>
        isPdf &&
        css`
            html {
                font-size: 100%;
                @media print {
                    font-size: 1.4em;
                }
            }
        `}

`;

export default GlobalStyle;
