import type { CommonSettingsType } from "../../settings/fetchCommonSettings";

export const COMMON_SETTINGS_INIT = "commonSettings/init";
export const UPDATE_QUERY_PATH = "commonSettings/updateQuery";
export const UPDATE_QUERY_VALUE = "commonSettings/updateQueryValue";
export const UPDATE_QUERY_PARAMS = "commonSettings/updateQueryParams";

type InitType = { type: typeof COMMON_SETTINGS_INIT; initState: CommonSettingsType };

type UpdateQueryPathType = { type: typeof UPDATE_QUERY_PATH; path: string };

type UpdateQueryParams = { type: typeof UPDATE_QUERY_PARAMS; params: Record<string, unknown> };

export const initCommonSettings = (initState: CommonSettingsType): InitType => ({
    type: COMMON_SETTINGS_INIT,
    initState,
});

export const updateQueryPath = (path: string): UpdateQueryPathType => ({
    type: UPDATE_QUERY_PATH,
    path,
});

export const updateQueryParams = (params: Record<string, unknown>): UpdateQueryParams => ({
    type: UPDATE_QUERY_PARAMS,
    params,
});

/**
 * Normally, query values should only be set via the URL and parsed on init.
 * However, for pagination, we need to keep the page in the carFilter reducer in sync with the page in the URL.
 * For this reason, we only allow updating of the page query value.
 */
type UpdatableQueryValues = Pick<CommonSettingsType["query"], "page">;
type UpdateQueryValueType = {
    type: typeof UPDATE_QUERY_VALUE;
    update: UpdatableQueryValues;
};

export const updateQueryValue = (update: UpdatableQueryValues): UpdateQueryValueType => ({
    type: UPDATE_QUERY_VALUE,
    update,
});

export type CommonSettingsActionsType = InitType | UpdateQueryPathType | UpdateQueryValueType | UpdateQueryParams;
