import { DfCarSortOrder, UsedCarDropdownSpec } from "../../utils/constants/filterConstants";
import type {
    UscContext,
    UscAspectRatioType,
    LegalSpecConfigType,
    UscObjectFitType,
    UscCustomerPromiseBannerType,
} from "../../../shared-logic/types/UscCommonTypes";
import { StockCarFilterId, UsedCarFilterId } from "../../../shared-logic/types/UscCommonTypes";
import type { CarFilterSettingsActionsType } from "../actions/CarFilterSettingsActions";
import {
    CAR_FILTER_SETTINGS_INIT,
    CAR_FILTER_SETTINGS_OVERRIDE,
    CAR_FILTER_SETTINGS_SET_USE_GLOBAL_STORE,
} from "../actions/CarFilterSettingsActions";
import type { CarFilterLabelType } from "../../utils/constants/labels";
import { carFilterLabels } from "../../utils/constants/labels";
import { SimilarCarFilterId } from "../../utils/constants/similarCarConstants";
import type { QuickSpecConfigType } from "../../../shared-logic/utils/uscUtils";
import { UscEnv } from "../../../shared-logic/utils/uscUtils";
import { MultipleChoiceValueSortOption } from "../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";

export type CarFilterContentBlockConfigType = {
    context: UscContext;
    items: string[];
    title: string;
    imageUrl: string;
};

export type CarFilterComingSoonBlockConfigType = {
    title: string;
    subtitle: string;
    imageUrl: string;
    link: string;
    showOnlineAvailable?: boolean;
    showComingSoon?: boolean;
};

// Type for {used: T; stock: T}
type ContextSettingType<T> = {
    used: T;
    stock: T;
};

export type CarFilterSettingsType = {
    initialized: boolean;
    useGlobalStore: boolean; // Indicates whether the global store should be used. This allows the Car Filter and Car Filter Header (top filters) to share the same store.
    uscEnv: UscEnv;
    distributorCode: string;
    dealerRangeValues: number[];
    labels: Record<CarFilterLabelType, string>;

    // Context specific settings
    showLocation: ContextSettingType<boolean>;
    dropdownSpecConfig: ContextSettingType<QuickSpecConfigType<UsedCarDropdownSpec>[]>;
    resultAspectRatio: ContextSettingType<UscAspectRatioType>;
    resultObjectFit: ContextSettingType<UscObjectFitType>;
    enableFinance: ContextSettingType<boolean>;
    enableStatusBadge: ContextSettingType<boolean>;
    similarCarsConfig: ContextSettingType<{ filterId: SimilarCarFilterId; value?: string }[]>;
    mainFilters: ContextSettingType<UsedCarFilterId[] | StockCarFilterId[]>;
    disableCarCategory: ContextSettingType<boolean>;
    disableMonthInRegistrationSpec: ContextSettingType<boolean>;
    legalSpecConfig: ContextSettingType<LegalSpecConfigType>;
    showZeroCo2SpecValues: ContextSettingType<boolean>;
    hideTopFilters: ContextSettingType<boolean>;
    hideZeroResultCountFilters: ContextSettingType<boolean>;
    localContactDealerFormUrl: ContextSettingType<string>;
    enableDynamicPriceFilter: ContextSettingType<boolean>;
    sortOptions: ContextSettingType<string[]>;
    multipleChoiceValueSortOption: ContextSettingType<MultipleChoiceValueSortOption>;
    disableSavedCars: ContextSettingType<boolean>;
    enableExperimentalTotalCountQuery: ContextSettingType<boolean>;
    enablePrivatelySoldVehicles: ContextSettingType<boolean>;
    enableUcProgramBadges: ContextSettingType<boolean>;
    enableVehicleAggregations: ContextSettingType<boolean>;
    vehicleAggregationsVersionCode: ContextSettingType<string>;
    financeUseBoldText: ContextSettingType<boolean>;
    urlDetailPage: ContextSettingType<string>;
    urlLandingPage: ContextSettingType<string>;
    enableRandomContentBlockIndex: ContextSettingType<boolean>;

    // These settings are only relevant for Used Cars, but to support them
    // in the same format as the other settings, they are included in the context specific settings
    // (with an empty stock value).
    enableZeroMileage: ContextSettingType<boolean>;
    enableBiasedSort: ContextSettingType<boolean>;
    warrantyFilterConfig: ContextSettingType<{
        showAnyWarranty: boolean;
        showTerms: boolean;
    }>;

    // Below properties don't have a context specific value
    contentBlockConfigurations: CarFilterContentBlockConfigType[];
    comingSoonBlockConfigurations: CarFilterComingSoonBlockConfigType[];
    topFiltersBackgroundImageUrl: string;
    ucProgramCustomerPromiseBanner: UscCustomerPromiseBannerType;
    nonUcProgramCustomerPromiseBanner: UscCustomerPromiseBannerType;
    privatelySoldCustomerPromiseBanner: UscCustomerPromiseBannerType;
};

export const carFilterSettingsInitialState: CarFilterSettingsType = {
    initialized: false,
    useGlobalStore: false,
    uscEnv: UscEnv.Production,
    distributorCode: "",
    showLocation: { used: false, stock: false },
    dropdownSpecConfig: {
        used: [
            {
                type: UsedCarDropdownSpec.Year,
                title: "Year",
                tooltip: "",
            },
            {
                type: UsedCarDropdownSpec.Mileage,
                title: "Mileage",
                tooltip: "",
            },
            {
                type: UsedCarDropdownSpec.FuelType,
                title: "Fuel Type",
                tooltip: "",
            },
            {
                type: UsedCarDropdownSpec.Transmission,
                title: "Transmission",
                tooltip: "",
            },
            {
                type: UsedCarDropdownSpec.Warranty,
                title: "Warranty",
                tooltip: "",
            },
            {
                type: UsedCarDropdownSpec.EcoLabel,
                title: "Eco Label",
                tooltip: "",
            },
            {
                type: UsedCarDropdownSpec.Doors,
                title: "Doors",
                tooltip: "",
            },
            {
                type: UsedCarDropdownSpec.PowerOutput,
                title: "Power Output",
                tooltip: "",
            },
        ],
        stock: [
            {
                type: UsedCarDropdownSpec.FuelType,
                title: "Fuel Type",
                tooltip: "",
            },
            {
                type: UsedCarDropdownSpec.Transmission,
                title: "Transmission",
                tooltip: "",
            },
            {
                type: UsedCarDropdownSpec.Doors,
                title: "Doors",
                tooltip: "",
            },
            {
                type: UsedCarDropdownSpec.PowerOutput,
                title: "Power Output",
                tooltip: "",
            },
        ],
    },
    resultAspectRatio: { used: "16:9", stock: "16:9" },
    resultObjectFit: { used: "cover", stock: "cover" },
    enableFinance: { used: true, stock: true },
    enableStatusBadge: { used: false, stock: false },
    similarCarsConfig: {
        used: [
            { filterId: SimilarCarFilterId.Model },
            { filterId: SimilarCarFilterId.Brand },
            { filterId: SimilarCarFilterId.FuelType },
            { filterId: SimilarCarFilterId.Transmission },
            { filterId: SimilarCarFilterId.Year, value: "2" },
            { filterId: SimilarCarFilterId.Price, value: "20" },
            { filterId: SimilarCarFilterId.Mileage, value: "20" },
        ],
        stock: [
            { filterId: SimilarCarFilterId.Model },
            { filterId: SimilarCarFilterId.Brand },
            { filterId: SimilarCarFilterId.FuelType },
            { filterId: SimilarCarFilterId.Transmission },
            { filterId: SimilarCarFilterId.Price, value: "20" },
        ],
    },
    mainFilters: {
        used: [UsedCarFilterId.Brand, UsedCarFilterId.Model, UsedCarFilterId.Price, UsedCarFilterId.Location],
        stock: [StockCarFilterId.Brand, StockCarFilterId.Model, StockCarFilterId.Price, StockCarFilterId.Location],
    },
    disableCarCategory: { used: false, stock: false },
    disableMonthInRegistrationSpec: { used: false, stock: false },
    legalSpecConfig: { used: {}, stock: {} },
    showZeroCo2SpecValues: { used: false, stock: false },
    hideTopFilters: { used: false, stock: false },
    hideZeroResultCountFilters: { used: false, stock: false },
    localContactDealerFormUrl: { used: "", stock: "" },
    enableDynamicPriceFilter: { used: false, stock: false },
    sortOptions: { used: [DfCarSortOrder.Published], stock: [DfCarSortOrder.Published] },
    multipleChoiceValueSortOption: {
        used: MultipleChoiceValueSortOption.Label,
        stock: MultipleChoiceValueSortOption.Label,
    },
    disableSavedCars: { used: false, stock: false },
    enableExperimentalTotalCountQuery: { used: false, stock: false },
    enablePrivatelySoldVehicles: { used: false, stock: false },
    enableUcProgramBadges: { used: false, stock: false },
    enableVehicleAggregations: { used: false, stock: false },
    vehicleAggregationsVersionCode: { used: "", stock: "" },
    financeUseBoldText: { used: false, stock: false },
    urlDetailPage: { used: "", stock: "" },
    urlLandingPage: { used: "", stock: "" },
    dealerRangeValues: [10, 20, 30],

    // Used Cars specific settings
    enableZeroMileage: { used: false, stock: false },
    enableBiasedSort: { used: false, stock: false },
    warrantyFilterConfig: {
        used: {
            showAnyWarranty: false,
            showTerms: false,
        },
        stock: {
            showAnyWarranty: false,
            showTerms: false,
        },
    },

    contentBlockConfigurations: [],
    comingSoonBlockConfigurations: [],
    topFiltersBackgroundImageUrl: "",
    labels: { ...carFilterLabels },
    ucProgramCustomerPromiseBanner: {
        bannerItems: [],
        title: "",
    },
    nonUcProgramCustomerPromiseBanner: {
        bannerItems: [],
        title: "",
    },
    privatelySoldCustomerPromiseBanner: {
        bannerItems: [],
        title: "",
    },
    enableRandomContentBlockIndex: { used: true, stock: true },
};

const CarFilterSettingsReducer = (
    state: CarFilterSettingsType = carFilterSettingsInitialState,
    action: CarFilterSettingsActionsType,
): CarFilterSettingsType => {
    switch (action.type) {
        case CAR_FILTER_SETTINGS_INIT: {
            return { ...state, ...action.initState, initialized: true };
        }

        case CAR_FILTER_SETTINGS_SET_USE_GLOBAL_STORE: {
            return { ...state, useGlobalStore: action.useGlobalStore };
        }

        case CAR_FILTER_SETTINGS_OVERRIDE: {
            return { ...state, ...action.override };
        }

        default:
            return state;
    }
};

export default CarFilterSettingsReducer;
