import type { MeasureType, UsedCarResultType, ReferenceType } from "../types/UscCommonTypes";
import type { EcoMeasureType } from "./uscEcoUtils";
/**
 * The source of truth for these values is the "Vehicle-Domain-Master-reference" sheet in Teams (TME - Used Car Program).
 */
export const TOYOTA_BRAND_ID = "38";
export const LEXUS_BRAND_ID = "22";

export const DF_CODE_HUB_CAR = "HUB";
export const DF_CODE_NOT_AVAILABLE = "NA";
export const DF_CODE_OTHER = "OT";

// Key used for localising the deliveryCost in cases where we read it from DF instead of TMEF.
export const DF_DELIVERY_COST_KEY = "vehicle.vehicleValue.deliveryCost";

export enum DfColourType {
    Exterior = "1",
    Interior = "2",
    Trim = "3",
    Other = "OT",
}

export enum DfVehicleStatus {
    Available = "1",
    Reserved = "2",
    Sold = "3",
}

export enum DfTransmissionId {
    Manual = "MT",
    Automatic = "AT",
    Other = "OT",
}

export enum DfColourId {
    White = "1",
    Black = "2",
    Grey = "3",
    Blue = "4",
    Red = "5",
    Brown = "6",
    Green = "7",
    Yellow = "8",
}

export enum DfFuelTypeId {
    Petrol = "1",
    Diesel = "2",
    Electric = "3",
    Hydrogen = "4",
    Hybrid = "5",
    PluginHybrid = "6", // plug-in hybrid
    Other = "OT",
}

export enum DfEquipmentCategoryCode {
    Comfort = "1",
    Exterior = "2",
    Seats = "3",
    Options = "4",
    Interior = "5",
    Safety = "6",
    Multimedia = "8",
    Others = "OT",
}

export enum DfExternalProductAttr {
    BrandCode = "1",
    BrandDescription = "2",
    ModelCode = "3",
    ModelDescription = "4",
    CarTypeCode = "5",
    CarTypeDescription = "6",
    BodyTypeCode = "7",
    BodyTypeDescription = "8",
    FuelTypeCode = "9",
    FuelTypeDescription = "10",
    ExteriorColourCode = "12",
    InteriorColourCode = "13",
    TrimColourCode = "14",
}

export enum DfExternalVehicleAttrs {
    sdkCode = "1",
    vehicleCode = "2",
}

// TODO: these names should be improved once it becomes clear what all of these codes mean
export enum DfServiceHistoryCode {
    HHC = "HHC", // description is "National Used Car Locator"
    Maintenance = "MNT",
    VehicleDelivery = "VDE",
    RPR = "RPR",
    RCS = "RCS",
}

export enum DfEmissionMeasureCode {
    Nedc = "NEDC",
    Nedc2 = "NEDC_2",
    Wltp = "WLTP",
}

export enum DfEmissionConsumptionTypeCode {
    CombinedNedc = "1",
    UrbanNedc = "2",
    ExtraUrbanNedc = "3",
    CombinedWltp = "4",
    ExtraHighWltp = "5",
    HighWltp = "6",
    LowWltp = "7",
    MediumWltp = "8",
    CityWltp = "9",
}

export enum DfEmissionCategoryCode {
    Aer = "AER",
    Eaear = "EAER",
    Per = "PER",
    Fc = "FC",
    Ec = "EC",
    Co2 = "CO2",
    Co2Nedc = "CO2-NEDC",
    FcNedc = "FC-NEDC",
}

export enum DfDocumentCategory {
    OdometerHistory = "ODOMETER_HISTORY",
    TechnicalReport = "TECHNICAL_REPORT",
    VehicleHistory = "VEHICLE_HISTORY",
}

export enum DfDocumentType {
    CarPass = "CARPASS",
    ToyotaApprovedUsed = "TOYOTA_APPROVED_USED",
    DamageHistory = "DAMAGE_HISTORY",
}

export enum DfVehicleOperationModeCode {
    Weighted = "WGT",
    UfWeighted = "UF_WGT",
    NotApplicable = "NA",
    ChargeSustaining = "CS",
    CdUfWeighted = "CD_UF_WGT",
    ChargeDepleting = "CD",
}

export enum DfPricingSourceCode {
    ManualPricing = "MANUAL_PRICING",
    PriceOnRequest = "PRICE_ON_REQUEST",
}

export enum DfEfficiencyClassCode {
    APlusPlusPlus = "A3",
    APlusPlus = "A2",
    APlus = "A1",
    A = "A",
    B = "B",
    C = "C",
    D = "D",
    E = "E",
    F = "F",
    G = "G",
}

export enum DfUcProgram {
    ToyotaPlus = "1", // deprecated
    LexusSelect = "2",
    No = "3",
    ToyotaApprovedUse = "4",
    LexusPremiumUsed = "5",
}

export enum DfTargetChannelCode {
    UCL = "UCL",
    LUCL = "LUCL",
    SCL = "SCL",
    LSCL = "LSCL",
}

export enum DfProductVersionIdentifier {
    A2P_LMC_SFX = "A2P_LMC_SFX",
    A2P_LMC = "A2P_LMC",
    ETG_NVC = "ETG_NVC",
    ARG_VRS_NO = "ARG_VRS_NO",
    KBA = "KBA",
}

export enum DfPreviousUsageCode {
    AGRICULTURE = "AGR",
    COMPANY = "CPY",
    DRIVING_SCHOOL = "DRV_SCL",
    MIXED = "MXD",
    OTHER = "OT",
    PRIVATE = "PRV",
    PUBLIC = "PUB",
    RENTAL_WO_DRIVER = "RTL_WO_DRV", // WITHOUT DRIVER
    RENTAL_W_DRIVER = "RTL_WT_DRV", // WITH DRIVER
    TAXI = "TAXI",
    PROFESSIONAL = "PRO",
}

export enum DfOwnershipCode {
    PrivatelyOwned = "1",
    RetailerOwned = "2",
}

export enum DfWarrantiesTypeCode {
    Manufacturer = "MANUFACTURER",
}

export type UsedCarCompareEquipmentCategoryType = {
    code: DfEquipmentCategoryCode;
    name: string;
    items: {
        code: string;
        name: string;
    }[];
};

export const defaultReference: ReferenceType = { description: "", code: "" };
export const defaultMeasure: MeasureType = { value: 0, unit: defaultReference };

type ecoMeasureValuesMockType = {
    co2NedcCombinedMock: EcoMeasureType;
    co2WltpCombinedMock: EcoMeasureType;
    fcNedcCombinedMock: EcoMeasureType;
    fcWltpCombinedMock: EcoMeasureType;
    ecCombinedMock: EcoMeasureType;
    eaerCombinedMock: EcoMeasureType;
    eaerCityMock: EcoMeasureType;
    perCombinedMock: EcoMeasureType;
    perCityMock: EcoMeasureType;
};

export const ecoMeasureValuesMock: ecoMeasureValuesMockType = {
    fcWltpCombinedMock: {
        value: 8.2,
        unit: { code: "13", description: "L/100 km" },
        type: { code: DfEmissionConsumptionTypeCode.CombinedWltp, description: "Combined" },
        measureSystem: { code: DfEmissionMeasureCode.Wltp, description: "Wltp" },
        category: { code: DfEmissionCategoryCode.Fc, description: "Fuel consumption" },
    },
    fcNedcCombinedMock: {
        value: 8.2,
        unit: { code: "13", description: "L/100 km" },
        type: { code: DfEmissionConsumptionTypeCode.CombinedNedc, description: "Combined" },
        measureSystem: { code: DfEmissionMeasureCode.Nedc, description: "Nedc" },
        category: { code: DfEmissionCategoryCode.Fc, description: "Fuel consumption" },
    },
    ecCombinedMock: {
        value: 186,
        unit: { code: "14", description: "kWh/100 km" },
        type: { code: DfEmissionConsumptionTypeCode.CombinedWltp, description: "Combined" },
        measureSystem: { code: DfEmissionMeasureCode.Wltp, description: "Nedc" },
        category: { code: DfEmissionCategoryCode.Ec, description: "Electric Consumption" },
        mode: { code: DfVehicleOperationModeCode.NotApplicable, description: "Not Applicable" },
    },
    co2NedcCombinedMock: {
        value: 30,
        unit: { code: "26", description: "g/km" },
        type: { code: DfEmissionConsumptionTypeCode.CombinedNedc, description: "Combined" },
        measureSystem: { code: DfEmissionMeasureCode.Nedc, description: "Nedc" },
    },
    co2WltpCombinedMock: {
        value: 30,
        unit: { code: "26", description: "g/km" },
        type: { code: DfEmissionConsumptionTypeCode.CombinedWltp, description: "Combined" },
        measureSystem: { code: DfEmissionMeasureCode.Wltp, description: "Wltp" },
    },
    eaerCombinedMock: {
        value: 45,
        unit: { code: "5", description: "km" },
        type: { code: DfEmissionConsumptionTypeCode.CombinedWltp, description: "Combined" },
        measureSystem: { code: DfEmissionMeasureCode.Wltp, description: "Wltp" },

        category: { code: DfEmissionCategoryCode.Eaear, description: "Equivalent All Electric Range" },
    },
    eaerCityMock: {
        value: 60,
        unit: { code: "5", description: "km" },
        type: { code: DfEmissionConsumptionTypeCode.CityWltp, description: "City" },
        measureSystem: { code: DfEmissionMeasureCode.Wltp, description: "Wltp" },
        category: { code: DfEmissionCategoryCode.Eaear, description: "Equivalent All Electric Range" },
    },
    perCityMock: {
        value: 436,
        unit: { code: "5", description: "km" },
        type: { code: DfEmissionConsumptionTypeCode.CityWltp, description: "City" },
        measureSystem: { code: DfEmissionMeasureCode.Wltp, description: "Wltp" },
        category: { code: DfEmissionCategoryCode.Per, description: "Pure Electric Range" },
    },
    perCombinedMock: {
        value: 318,
        unit: { code: "5", description: "km" },
        type: { code: DfEmissionConsumptionTypeCode.CombinedWltp, description: "Combined" },
        measureSystem: { code: DfEmissionMeasureCode.Wltp, description: "Wltp" },
        category: { code: DfEmissionCategoryCode.Per, description: "Pure Electric Range" },
    },
};

export const defaultUsedCarResult: UsedCarResultType = {
    id: "",
    vehicleStatus: { code: DfVehicleStatus.Available, description: "" },
    sourceCode: "",
    warranty: null,
    images: [],
    mileage: defaultMeasure,
    exteriorColour: "",
    interiorColour: "",
    trimColour: "",
    dealerId: "",
    dealerLocalId: "",
    isHubCar: false,
    history: {
        registrationDate: "",
        localRegistrationDate: "",
        events: [],
        previousAccident: null,
        previousOwners: null,
        previousUsage: null,
    },
    promotion: null,
    price: {
        sellingPriceInclVAT: 0,
        sellingPriceExclVAT: 0,
        pricingSource: { code: DfPricingSourceCode.ManualPricing, description: "" },
    },
    financingOffers: [],
    currencyCode: "",
    financeInfo: null,
    insuranceQuote: null,
    vehicleValue: { marketPrice: null, cataloguePrice: null, valuationDate: null, deliveryCost: null },
    licensePlate: "",
    ucProgram: defaultReference,
    ownershipTax: null,
    vin: "",
    productionDate: "",
    product: {
        brand: defaultReference,
        model: "",
        modelYear: "",
        localModelCode: "",
        bodyType: "",
        versionName: "",
        doors: 0,
        seats: 0,
        length: defaultMeasure,
        height: defaultMeasure,
        width: defaultMeasure,
        bootCapacity: defaultMeasure,
        grade: { gradeDescription: "" },
        carType: defaultReference,
        engine: {
            description: "",
            fuelType: defaultReference,
            marketingFuelType: defaultReference as ReferenceType<DfFuelTypeId>,
            displayFuelType: "",
            hybrid: false,
            plugin: false,
            cylinders: 0,
            powerOutputHorsepower: defaultMeasure,
            fiscalPower: defaultMeasure,
            displacement: defaultMeasure,
            engineKey: defaultReference,
        },
        performance: {
            maxSpeed: defaultMeasure,
            acceleration: defaultMeasure,
        },
        transmission: {
            name: "",
            gearbox: "",
            transmissionType: null,
        },
        eco: {
            label: defaultReference,
            euroClass: "",
            efficiencyClass: null,
            co2Emission: [ecoMeasureValuesMock.co2WltpCombinedMock],
            consumption: [ecoMeasureValuesMock.fcWltpCombinedMock],
        },
        externalProductAttributes: [],
        productVersionIdentifiers: [],
    },
    nextInspectionDate: "",
    isPledgedSale: null,
    enrichmentStatus: {},
    externalVehicleAttributes: [],
    ownership: { code: DfOwnershipCode.RetailerOwned, description: "" },

    // Car Filter Extra
    title: "",
    description: "",
    monthlyPrice: 0,
    dealer: null,
    trackingProductName: "",
};
