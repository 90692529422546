import type { ComponentType, SVGProps } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { UscVehicleSaleType } from "../../../../../shared-logic/types/UscCommonTypes";
import type { CarFilterDispatchType } from "../../../../redux/store";
import { useCarFilterSelector } from "../../../../redux/store";
import { getVehicleSaleType } from "../../../../../shared-logic/utils/uscUtils";
import { showModal } from "../../../../../../common-deprecated/redux/actions/ModalActions";
import * as Styles from "./UsedCarResultSaleTypeBadge.styles";
import Icon, {
    IconProfile,
    IconApproved,
    IconNonApproved,
} from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { MODAL_FILTER_VEHICLE_SALE_TYPE } from "../../../../utils/modalConstants";

const saleTypeIcons: Record<UscVehicleSaleType, ComponentType<SVGProps<SVGSVGElement>>> = {
    [UscVehicleSaleType.Private]: IconProfile,
    [UscVehicleSaleType.Approved]: IconApproved,
    [UscVehicleSaleType.NonApproved]: IconNonApproved,
};

type UsedCarResultSaleTypeBadgeProps = {
    vehicleForSaleId: string;
    brand: "toyota" | "lexus";
    inModal?: boolean;
};

const UsedCarResultSaleTypeBadge = ({
    vehicleForSaleId,
    brand,
    inModal,
}: UsedCarResultSaleTypeBadgeProps): JSX.Element | null => {
    const dispatch = useDispatch<CarFilterDispatchType>();

    const enablePrivatelySoldVehicles = useCarFilterSelector(
        ({ carFilters, carFilterSettings }) => carFilterSettings.enablePrivatelySoldVehicles[carFilters.currentFilter],
    );
    const enableUcProgramBadges = useCarFilterSelector(
        ({ carFilters, carFilterSettings }) => carFilterSettings.enableUcProgramBadges[carFilters.currentFilter],
    );
    const customerPromiseBannerItemsAvailable = useCarFilterSelector(
        (state) =>
            !!state.carFilterSettings.ucProgramCustomerPromiseBanner?.bannerItems?.length &&
            !!state.carFilterSettings.nonUcProgramCustomerPromiseBanner?.bannerItems?.length,
    );
    const privatelySoldCustomerPromiseBannerItems = useCarFilterSelector(
        (state) => state.carFilterSettings.privatelySoldCustomerPromiseBanner.bannerItems,
    );
    const vehicleResult = useCarFilterSelector(
        (state) => state.carResults.dfCarResults.find((result) => result.result?.id === vehicleForSaleId)?.result,
    );
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);

    if (
        !vehicleResult ||
        !enableUcProgramBadges ||
        // It's a requirement for the modal data to be available in order to show the badge
        !customerPromiseBannerItemsAvailable ||
        (enablePrivatelySoldVehicles && !privatelySoldCustomerPromiseBannerItems?.length)
    )
        return null;

    const { label: vehicleSaleTypeLabel, vehicleSaleType } = getVehicleSaleType(
        vehicleResult,
        brand,
        enablePrivatelySoldVehicles,
        commonSettings,
    );

    const SaleTypeIcon = saleTypeIcons[vehicleSaleType];

    return (
        <Styles.Wrapper
            type="button"
            disabled={inModal}
            onClick={(evt) => {
                evt.stopPropagation();
                dispatch(showModal({ type: MODAL_FILTER_VEHICLE_SALE_TYPE, vehicleForSaleId, vehicleSaleType }));
            }}
        >
            <Styles.Label>
                <SaleTypeIcon width={20} height={20} />
                {vehicleSaleTypeLabel}
            </Styles.Label>

            {!inModal && (
                <Styles.InfoIconWrapper>
                    <Icon variant="info" />
                </Styles.InfoIconWrapper>
            )}
        </Styles.Wrapper>
    );
};

export default UsedCarResultSaleTypeBadge;
