import React, { useCallback, useState } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import ButtonSlider from "../../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/ButtonSlider";
import type {
    MinMaxSliderFilterConfigType,
    SliderButtonType,
} from "../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { SLIDER_INCREASING } from "../../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import type { MinMaxSliderFilterIdType } from "../../../../utils/constants/filterConstants";
import type { CarFilterDispatchType } from "../../../../redux/store";
import { useCarFilterSelector } from "../../../../redux/store";
import { setSliderValue } from "../../../../redux/actions/CarFiltersActions";
import * as Flex from "../../../../../../common-deprecated/styles/v2/toyota/globals/Flex";
import { getAemCarFilterTrackParamsSelector, trackAemMinMaxSliderFilter } from "../../../../utils/tracking";
import RangeSliderInput from "../../RangeSliderInput/RangeSliderInput";

type MinMaxSliderFilterType = { filterId: MinMaxSliderFilterIdType };

const MinMaxSliderFilter = ({ filterId }: MinMaxSliderFilterType): JSX.Element => {
    const filter: MinMaxSliderFilterConfigType = useCarFilterSelector((state) => state.carFilters[filterId]);
    const trackParams = useCarFilterSelector(getAemCarFilterTrackParamsSelector(filterId), shallowEqual);
    const dispatch = useDispatch<CarFilterDispatchType>();

    const { currentMinValue, currentMaxValue, minValue, maxValue, valueLabelConfig, unit } = filter;
    const { minValueText, maxValueText, value: valueLabel } = valueLabelConfig;

    const [currentSliderValues, setCurrentSliderValues] = useState({ min: currentMinValue, max: currentMaxValue });

    const getValueLabel = useCallback(
        (value: number): string => valueLabel.replace("{value}", value.toString()),
        [valueLabel],
    );

    const setValue = useCallback(
        (newValue: number, type: SliderButtonType): void => {
            dispatch(setSliderValue(type, newValue, filterId));
            trackAemMinMaxSliderFilter(trackParams, type, newValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterId, trackParams],
    );

    return (
        <Flex.Row>
            <Flex.Col width={1}>
                <RangeSliderInput
                    initialValues={{ min: minValue, max: maxValue }}
                    currentValues={{ min: currentSliderValues.min, max: currentSliderValues.max }}
                    initialClampValues={{ min: minValue, max: maxValue }}
                    setValueFn={setValue}
                    labels={{
                        from: minValueText,
                        to: maxValueText,
                        [unit?.useAsPrefix ? "prefix" : "suffix"]: unit?.value,
                    }}
                    name={filter.label}
                />

                <ButtonSlider
                    maxValue={maxValue}
                    minValue={minValue}
                    step={filter.step}
                    currentMaxValue={currentMaxValue}
                    currentMinValue={currentMinValue}
                    setValue={setValue}
                    order={SLIDER_INCREASING}
                    getValueLabel={getValueLabel}
                    enableMinValue
                    filterName={filter.label}
                    setSliderValues={(min, max) => setCurrentSliderValues({ min, max })}
                />
            </Flex.Col>
        </Flex.Row>
    );
};

export default MinMaxSliderFilter;
