import React from "react";
import { shallowEqual } from "react-redux";
import { useCarFilterSelector } from "../../../../redux/store";
import type { FullQuickSpecConfigType } from "../../../../../shared-logic/utils/uscUtils";
import { UscQuickSpec } from "../../../../../shared-logic/utils/uscUtils";
import * as Tooltip from "../../../../../../common-deprecated/styles/v2/toyota/components/Tooltip";
import DisclaimerIconWrapper from "../../common/DisclaimerIconWrapper";
import { CarFilterDisclaimerType, getCarFilterDisclaimerSelector } from "../../../../utils/disclaimerUtils";
import EfficiencyClassLabel from "../../../../../shared-components/toyota/efficiency-class/EfficiencyClassLabel";
import * as Styles from "./styles/UsedCarResultStyles";
import type { UscEcoType, UscEcoValueMeasureSystem } from "../../../../../shared-logic/utils/uscEcoUtils";
import EmissionLabelWrapper from "../../../../../../common-deprecated/styles/v2/toyota/components/EmissionLabelWrapper";
import { formatValueUnit } from "../../../../../../common-deprecated/utils/specs";

type UsedCarResultSpecsProps = {
    specs: FullQuickSpecConfigType[];
    vehicleForSaleId: string;
    eco: UscEcoType | null;
    scrollable?: boolean;
};

type SpecListItemProps = {
    value: string;
    title: string;
    unit?: string;
    tooltipReference: UscQuickSpec | UscEcoValueMeasureSystem | undefined;
    isEfficiencyClass: boolean;
    vehicleForSaleId: string;
    eco: UscEcoType | null;
    type: UscQuickSpec;
};

const SpecListItem = (props: SpecListItemProps): JSX.Element | null => {
    const { value, title, unit, type, tooltipReference, isEfficiencyClass, vehicleForSaleId, eco } = props;
    const disclaimer = useCarFilterSelector(
        getCarFilterDisclaimerSelector(vehicleForSaleId, CarFilterDisclaimerType.Spec, tooltipReference),
        shallowEqual,
    );

    return (
        <Styles.SpecListItem key={type} data-testid="specs-list-item" isEfficiencyClass={isEfficiencyClass}>
            <div>
                <Styles.SpecLabel>{title}</Styles.SpecLabel>
                {isEfficiencyClass ? (
                    <EmissionLabelWrapper>
                        <EfficiencyClassLabel ecoInfo={eco} code={value as string} />
                    </EmissionLabelWrapper>
                ) : (
                    <Styles.SpecValueWrapper>
                        <Styles.SpecValue>{formatValueUnit(value, unit)}</Styles.SpecValue>

                        {disclaimer && (
                            <Tooltip.TooltipWrapper width={130}>
                                <DisclaimerIconWrapper
                                    type={CarFilterDisclaimerType.Spec}
                                    reference={tooltipReference}
                                    vehicleForSaleId={vehicleForSaleId}
                                />
                            </Tooltip.TooltipWrapper>
                        )}
                    </Styles.SpecValueWrapper>
                )}
            </div>
        </Styles.SpecListItem>
    );
};

const UsedCarResultSpecs = ({
    specs,
    vehicleForSaleId,
    eco,
    scrollable,
}: UsedCarResultSpecsProps): JSX.Element | null => (
    <Styles.SpecsList data-testid="specs-list" scrollable={scrollable}>
        {specs.map(({ value, title, unit, type, tooltipReference }) => (
            <SpecListItem
                isEfficiencyClass={type === UscQuickSpec.EfficiencyClass}
                tooltipReference={tooltipReference}
                value={value}
                title={title}
                unit={unit}
                vehicleForSaleId={vehicleForSaleId}
                eco={eco}
                type={type}
                key={type}
            />
        ))}
    </Styles.SpecsList>
);

export default UsedCarResultSpecs;
