import type { RefObject } from "react";
import { useEffect, useState } from "react";
import { useAemNavOffset } from "../../../../common-deprecated/hooks";

/**
 * Returns a boolean indicating whether the filter bar should be fixed or not.
 * This is determined by checking if the top of the results container is in the viewport.
 */
const useFixedFilterBar = (resultsContainer: RefObject<HTMLElement>, offset: number = 0): boolean => {
    const [isFixed, setFixed] = useState(false);
    const navOffset = useAemNavOffset();

    useEffect(() => {
        const updateScroll = (): void => {
            const containerIntersectsHeader =
                resultsContainer.current && navOffset + offset > resultsContainer.current.getBoundingClientRect().top;

            if (containerIntersectsHeader && !isFixed) {
                setFixed(true);
            } else if (!containerIntersectsHeader && isFixed) {
                setFixed(false);
            }
        };

        // Execute once during render to make sure it is still correct (if nav height changes or during mount)..
        updateScroll();

        window.addEventListener("resize", updateScroll);
        window.addEventListener("scroll", updateScroll);

        return () => {
            window.removeEventListener("resize", updateScroll);
            window.removeEventListener("scroll", updateScroll);
        };
    }, [navOffset, resultsContainer, isFixed, offset]);

    return isFixed;
};

export default useFixedFilterBar;
