import type { Dispatch, RefObject, SetStateAction } from "react";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useShouldShowModal } from "../../../../common-deprecated/hooks/useShouldShowModal";
import { useCloseOnOutsideClick } from "../../../../common-deprecated/hooks";
import { showModal } from "../../../../common-deprecated/redux/actions/ModalActions";
import { MODAL_CONTENT } from "../../../../common-deprecated/utils/modalConstants";
import type { CarFilterDispatchType } from "../../redux/store";

const usePromotionModal = (
    popupRef: RefObject<HTMLDivElement>,
    tooltipcontent: JSX.Element | JSX.Element[],
): { showPopup: boolean; setShowPopup: Dispatch<SetStateAction<boolean>> } => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const [showPopup, setShowPopup] = useState<boolean>(false);

    // If a modal needs to be rendered the trigger for the modal should always be onClick and not hover.
    // This is why useShouldShowModal is also used here as this behaviour needs to be determined here while the other logic happens in PopoverTooltip
    const { modalVisible } = useShouldShowModal(popupRef);

    useCloseOnOutsideClick(popupRef, () => setShowPopup(false));

    // Show modal if necessary.
    useEffect(() => {
        if (modalVisible && showPopup) {
            dispatch(
                showModal({
                    type: MODAL_CONTENT,
                    useHTML: true,
                    onClose: () => setShowPopup(false),
                    content: tooltipcontent || "",
                }),
            );
        }
    }, [modalVisible, showPopup]); // eslint-disable-line react-hooks/exhaustive-deps

    return { showPopup, setShowPopup };
};

export default usePromotionModal;
