import React, { forwardRef } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import ButtonPrimary from "../../../../../../common-deprecated/components/aem/toyota/ButtonPrimary";
import ButtonSecondary from "../../../../../../common-deprecated/components/aem/toyota/ButtonSecondary";
import { usedCompareAddCars } from "../../../../../../common-deprecated/features/compare/redux/actions/UsedCarCompareExternalDataActions";
import type { UsedCarResultType } from "../../../../../shared-logic/types/UscCommonTypes";
import { UscContext } from "../../../../../shared-logic/types/UscCommonTypes";
import { DfVehicleStatus } from "../../../../../shared-logic/utils/dfConstants";
import { getUscCashPriceFromVehicle, showUscPrice } from "../../../../../shared-logic/utils/uscPriceUtils";
import {
    getUscUrl,
    getVehicleTitle,
    showFuelTypeTag,
    VEHICLE_AGGREGATION_THRESHOLD,
} from "../../../../../shared-logic/utils/uscUtils";
import { getToyotaButtonColor, getContactDealerCta } from "../../../../utils/ctaUtils";
import Backdrop from "../../../../../../common-deprecated/styles/v2/toyota/components/Backdrop";
import FuelTypeLabel from "../../../../../../common-deprecated/styles/v2/toyota/components/FuelTypeLabel";
import { IconClose, IconMarker } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { EcoTagType } from "../../../../../../common-deprecated/types/CommonTypes";
import { isRetailer, redirect } from "../../../../../../common-deprecated/utils";
import { trackEvent } from "../../../../../../common-deprecated/utils/tracking";
import useCarResultCard from "../../../../hooks/car-results/useCarResultCard";
import useUsedCarResultSpecs from "../../../../hooks/car-results/useUsedCarResultSpecs";
import type { CarFilterDispatchType } from "../../../../redux/store";
import { useCarFilterSelector } from "../../../../redux/store";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import { useCommonLabel } from "../../../../../../common-deprecated/utils/commonLabels";
import {
    getDealerDistance,
    getUscVehicleUrlInfoFromResult,
    getUsedCarResultImageAltTag,
} from "../../../../utils/helpers";
import {
    getAemProductTrackingInfo,
    getAemTrackingDefaults,
    trackAemShowMoreCta,
    trackContactDealerCta,
    trackResultClick,
    trackResultImageInteraction,
} from "../../../../utils/tracking";
import CompareButton from "../CompareButton";
import ResultImageSlider from "../image-slider/ResultImageSlider";
import ResultPlaceholderImage from "../image-slider/ResultPlaceholderImage";
import * as ResultImageSliderStyles from "../image-slider/styles/ResultImageSliderStyles";
import CarResultPrice from "../price/CarResultPrice";
import SaveButton from "./SaveButton";
import UsedCarPromotion from "./UsedCarPromotion";
import UsedCarResultDeliveryBadge from "./UsedCarResultDeliveryBadge";
import UsedCarResultSpecs from "./UsedCarResultSpecs";
import * as BadgeStyles from "./styles/BadgeStyles";
import * as Styles from "./styles/UsedCarResultStyles";
import UsedCarResultSaleTypeBadge from "./UsedCarResultSaleTypeBadge";
import useLocalContactDealerClick from "../../../../../shared-logic/hooks/useLocalContactDealerClick";
import Icon from "../../../../../shared-components/toyota/styles/atoms/Icon";

type UsedCarResultProps = {
    result: UsedCarResultType;
    disableCompare?: boolean;
    isCarFilterResults?: boolean;
    isSimilarCar?: boolean;
    disableFavorite?: boolean;
    disableContactDealer?: boolean;
};

/**
 * WARNING: This component has a "twin" called usedCarResultLoader which is the "Loading" version of this component.
 * Make sure if any style updates are done the loader matches it.
 */
const UsedCarResult = forwardRef<HTMLElement, UsedCarResultProps>((props, ref) => {
    const { result, disableCompare, isCarFilterResults, isSimilarCar, disableFavorite, disableContactDealer } = props;
    const { images, id, financeInfo, vehicleStatus, threeSixtyView, product, price, vehicleCount } = result;
    const { dealer, monthlyPrice, promotion } = result;
    const { brand, model, versionName, engine, eco } = product;
    const { marketingFuelType } = engine;

    const dispatch = useDispatch<CarFilterDispatchType>();
    const commonSettings = useCarFilterSelector((state) => state.commonSettings);
    const carFilters = useCarFilterSelector((state) => state.carFilters);
    const carFilterSettings = useCarFilterSelector((state) => state.carFilterSettings);
    const currentFilter = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const carUrl = getUscUrl(
        commonSettings,
        carFilterSettings.urlDetailPage[currentFilter],
        currentFilter,
        getUscVehicleUrlInfoFromResult(result),
    );

    const dealerDistance = getDealerDistance(dealer, carFilters, commonSettings);

    const showLocationSetting = carFilterSettings.showLocation[currentFilter];
    const vehicleAggregationsActive = carFilterSettings.enableVehicleAggregations[currentFilter];
    const aspectRatio = carFilterSettings.resultAspectRatio[currentFilter];
    const objectFit = carFilterSettings.resultObjectFit[currentFilter];
    const showStatusBadge = carFilterSettings.enableStatusBadge[currentFilter];

    const showLocation = showLocationSetting && !vehicleAggregationsActive && !isRetailer(commonSettings);

    const isInCompareList = useCarFilterSelector(
        (state) => state.usedCarCompareExternalData.cars.some((car) => car.id === id),
        shallowEqual,
    );

    const locationDistanceLabel = useCarFilterLabel("carFilterLocationDistance", { value: dealerDistance });
    const selectCarLabel = useCarFilterLabel("carFilterSelectCar");
    const showMoreLabel = useCarFilterLabel("carFilterShowMore");
    const showMoreLabelAria = useCarFilterLabel("carFilterShowMoreAria");
    const closeLabel = useCommonLabel("close");

    const { moreSpecsRef, toggleOpen, moreSpecsExpanded } = useCarResultCard(isCarFilterResults);
    const { requiredSpecs, allSpecs, hasShowMore } = useUsedCarResultSpecs(result);
    const altTag = getUsedCarResultImageAltTag(result);
    const cashPrice = getUscCashPriceFromVehicle(result);
    const showPrice = showUscPrice(price.pricingSource?.code);
    const distanceLabel = showLocation && dealerDistance ? locationDistanceLabel : undefined;
    const { code: fuelTypeCode, description: fuelTypeName } = marketingFuelType;
    const vehicleTitle = getVehicleTitle(product);

    const showFuelType = fuelTypeCode && showFuelTypeTag(fuelTypeCode);

    const localContactDealerClick = useLocalContactDealerClick({
        context: currentFilter,
        dealer,
        vehicle: result,
        detailPageUrl: carFilterSettings.urlDetailPage[currentFilter],
        localContactDealerFormUrl: carFilterSettings.localContactDealerFormUrl[currentFilter],
    });

    const contactDealerCta = useCarFilterSelector(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        ({ carFilters, commonSettings }) =>
            getContactDealerCta(
                result,
                carFilterSettings.urlDetailPage[carFilters.currentFilter],
                commonSettings,
                carFilterSettings,
                carFilters.currentFilter,
                dispatch,
                localContactDealerClick,
            ),
        shallowEqual,
    );

    const showContactDealerCta = !disableContactDealer && !!contactDealerCta;

    const trackingProductData = getAemProductTrackingInfo(currentFilter, result);

    const trackedCompareClick = (): void => {
        trackEvent(
            {
                ...getAemTrackingDefaults(currentFilter),
                action: "add-compare",
                label: id,
            },
            { product: trackingProductData },
        );
        // DealerDistance has to be given to the compare via the dispatch for now because the distances are stored only in the carfilter redux state.
        dispatch(
            usedCompareAddCars(
                [{ id, product: { brand: { description: brand.description, code: "" }, model }, dealerDistance }],
                -1,
            ),
        );
    };

    // location label
    const hubCarLabel = useCarFilterLabel("carFilterLocationHubDealer");
    let locationLabel: string | undefined;
    if (showLocation) {
        if (dealer) locationLabel = dealer.address.city;
        else if (result.isHubCar) locationLabel = hubCarLabel;
    }

    // Vehicle aggregation labels
    const vehicleAggregationCountLabel = useCarFilterLabel(
        (vehicleCount ?? 0) < VEHICLE_AGGREGATION_THRESHOLD
            ? "carFilterVehicleAggregationLess"
            : "carFilterVehicleAggregationMore",
    );
    const vehicleAggregationLabel = useCarFilterLabel("carFilterVehicleAggregationCarsAvailable");

    return (
        <Styles.Wrapper
            ref={ref}
            data-testid="used-car-result"
            data-url={carUrl}
            onClick={() => {
                trackResultClick(currentFilter, result, isSimilarCar);
                redirect(carUrl);
            }}
            isCarFilterResults={isCarFilterResults}
        >
            <div>
                <Styles.ImageWrapper onClick={(evt) => evt.stopPropagation()}>
                    <BadgeStyles.Group>
                        {promotion && <UsedCarPromotion promotionDisclaimer={promotion} />}
                        {showStatusBadge && vehicleStatus.code !== DfVehicleStatus.Available && (
                            <BadgeStyles.Badge>{vehicleStatus.description}</BadgeStyles.Badge>
                        )}
                    </BadgeStyles.Group>

                    {images.length === 0 ? (
                        <a href={carUrl}>
                            <ResultImageSliderStyles.SliderViewport
                                objectFit={objectFit}
                                hasAspectRatio43={aspectRatio === "4:3"}
                            >
                                <ResultPlaceholderImage />
                            </ResultImageSliderStyles.SliderViewport>
                        </a>
                    ) : (
                        <ResultImageSlider
                            images={images.map(({ url }) => ({ src: url }))}
                            altTag={altTag}
                            carUrl={carUrl}
                            urlTrack={() => trackResultClick(currentFilter, result, isSimilarCar)}
                            interactionTrack={(src) => {
                                trackResultImageInteraction(currentFilter, result, isSimilarCar, src);
                            }}
                            objectFit={objectFit}
                            aspectRatio={aspectRatio}
                            show360Icon={!!threeSixtyView}
                        />
                    )}
                </Styles.ImageWrapper>
                {!!vehicleCount && (
                    <Styles.VehicleAggregation>
                        <Icon
                            variant={
                                vehicleCount < VEHICLE_AGGREGATION_THRESHOLD
                                    ? "vehicle-aggregations-fewer-cars"
                                    : "vehicle-aggregations-multiple-cars"
                            }
                        />
                        <Styles.VehicleAggregationText>
                            <span>{vehicleAggregationCountLabel}</span>
                            {vehicleAggregationLabel}
                        </Styles.VehicleAggregationText>
                    </Styles.VehicleAggregation>
                )}
            </div>

            <Styles.Header>
                <Styles.Title>
                    {vehicleTitle}&nbsp;{/* Added &nbsp; for a11y reasons (PR#8184) */}
                    <Styles.SubTitle>{versionName}</Styles.SubTitle>
                </Styles.Title>

                {!!locationLabel && (
                    <Styles.Location>
                        <IconMarker aria-hidden="true" />
                        {locationLabel} {!!distanceLabel && distanceLabel}
                    </Styles.Location>
                )}
                {showFuelType && <FuelTypeLabel type={EcoTagType.Hybrid}>{fuelTypeName}</FuelTypeLabel>}
            </Styles.Header>

            <div>
                <Styles.SaleTypeBadges>
                    <UsedCarResultSaleTypeBadge vehicleForSaleId={id} brand={commonSettings.brand} />
                </Styles.SaleTypeBadges>

                <Styles.Specs isCarFilterResults={isCarFilterResults}>
                    <UsedCarResultSpecs vehicleForSaleId={id} specs={requiredSpecs} eco={eco} />

                    {hasShowMore && (!moreSpecsExpanded || isCarFilterResults) && (
                        <Styles.ButtonMoreSpecs
                            type="button"
                            onClick={(evt) => {
                                if (moreSpecsExpanded) {
                                    trackAemShowMoreCta(currentFilter, result, "expand", isSimilarCar);
                                }
                                evt.stopPropagation();
                                toggleOpen();
                            }}
                            aria-label={`${showMoreLabelAria} ${vehicleTitle} ${versionName}`}
                        >
                            <span>{showMoreLabel}</span>
                        </Styles.ButtonMoreSpecs>
                    )}

                    <Styles.MoreSpecsDialog
                        hidden={!moreSpecsExpanded}
                        ref={moreSpecsRef}
                        onClick={(evt) => evt.stopPropagation()}
                        isCarFilterResults={isCarFilterResults}
                        carImageAspectRatio43={aspectRatio === "4:3"}
                    >
                        <Styles.MoreSpecsDialogInner>
                            <Styles.MoreSpecsDialogCloseButton
                                type="button"
                                onClick={() => {
                                    trackAemShowMoreCta(currentFilter, result, "reduce", isSimilarCar);
                                    toggleOpen();
                                }}
                                aria-label={closeLabel}
                            >
                                <IconClose aria-hidden="true" />
                            </Styles.MoreSpecsDialogCloseButton>
                            <Styles.Header>
                                <Styles.Title as="div">
                                    {vehicleTitle}&nbsp;{/* Added &nbsp; for a11y reasons (PR#8184) */}
                                    <Styles.SubTitle>{versionName}</Styles.SubTitle>
                                </Styles.Title>
                            </Styles.Header>
                            <UsedCarResultSpecs vehicleForSaleId={id} specs={allSpecs} eco={eco} scrollable />
                            <Styles.CtaGroup isCarFilterResults={isCarFilterResults}>
                                <ButtonPrimary
                                    color={getToyotaButtonColor(currentFilter)}
                                    fullWidth
                                    href={carUrl}
                                    stopPropagation
                                >
                                    {selectCarLabel}
                                </ButtonPrimary>
                            </Styles.CtaGroup>
                        </Styles.MoreSpecsDialogInner>
                    </Styles.MoreSpecsDialog>
                    {moreSpecsExpanded && !isCarFilterResults && <Backdrop zIndex={1050} />}
                </Styles.Specs>
            </div>

            <Styles.Prices isCarFilterResults={isCarFilterResults}>
                {showPrice && (
                    <CarResultPrice
                        vehicleForSaleId={id}
                        cashPrice={cashPrice}
                        monthlyPrice={monthlyPrice}
                        financeInfo={financeInfo}
                    />
                )}
            </Styles.Prices>

            <Styles.CtaGroup isCarFilterResults={isCarFilterResults}>
                <ButtonPrimary
                    color={getToyotaButtonColor(currentFilter)}
                    fullWidth
                    href={carUrl}
                    stopPropagation
                    onClick={() => trackResultClick(currentFilter, result, isSimilarCar)}
                    ariaLabel={`${vehicleTitle} ${versionName}`}
                >
                    {selectCarLabel}
                </ButtonPrimary>

                {showContactDealerCta && (
                    <ButtonSecondary
                        onClick={(evt) => {
                            trackContactDealerCta(currentFilter, result, isSimilarCar);
                            contactDealerCta.onClick?.(evt);
                        }}
                        fullWidth
                        stopPropagation
                    >
                        {contactDealerCta.label}
                    </ButtonSecondary>
                )}
            </Styles.CtaGroup>

            <Styles.DeliveryAndButtonGroup>
                <UsedCarResultDeliveryBadge result={result} />
                <Styles.ButtonGroup isCarFilterResults={isCarFilterResults}>
                    {currentFilter !== UscContext.Stock && !disableCompare && (
                        <CompareButton onClick={trackedCompareClick} isActive={isInCompareList} />
                    )}
                    {!disableFavorite && <SaveButton result={result} isSimilarCar={isSimilarCar} />}
                </Styles.ButtonGroup>
            </Styles.DeliveryAndButtonGroup>
        </Styles.Wrapper>
    );
});

export default UsedCarResult;
