import React, { useState } from "react";
import type { PriceFilterConfigType } from "../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import { SLIDER_INCREASING } from "../../../../../shared-logic/features/filters/utils/constants/filterConfigConstants";
import type { ButtonSliderType } from "../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/ButtonSlider";
import ButtonSlider from "../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/ButtonSlider";
import type { FinanceOptionType } from "../../../../../common-deprecated/types/CommonTypes";
import ButtonSliderRTL from "../../../../../common-deprecated/features/filters/components/toyota/filters/ButtonSlider/ButtonSliderRTL";
import * as commonSettingUtils from "../../../../../common-deprecated/settings/utils/commonSettingUtils";
import { useCommonSelector } from "../../../../../common-deprecated/redux/commonStore";
import usePriceFilterSlider from "../../../hooks/usePriceFilterSlider";
import usePriceFilter from "../../../hooks/usePriceFilter";
import RangeSliderInput from "../RangeSliderInput/RangeSliderInput";

type PriceFilterSliderType = {
    priceFilter: PriceFilterConfigType;
    setValue: (type: "max" | "min", value: number, financeOption: FinanceOptionType) => void;
    activeFilterType: FinanceOptionType;
};
const PriceFilterSlider = ({ priceFilter, setValue, activeFilterType }: PriceFilterSliderType): JSX.Element => {
    const filterProps = usePriceFilter(priceFilter, activeFilterType);
    const isRTL = useCommonSelector((state) => commonSettingUtils.isRTL(state.commonSettings));
    const { activeFinanceOption, formatPrice } = filterProps;
    const { cash, monthly } = priceFilter;
    const {
        currentMinValue,
        currentMaxValue,
        minValue,
        maxValue,
        step,
        valueLabelConfig,
        customMinValue,
        customMaxValue,
        unit,
    } = activeFinanceOption === "monthly" ? monthly : cash;
    const { minValueText, maxValueText, value } = valueLabelConfig;

    const { clippedMin, clippedMax, setValueFn, getValueLabelFn } = usePriceFilterSlider(
        minValue,
        maxValue,
        customMinValue,
        customMaxValue,
        value,
        setValue,
        activeFinanceOption,
        formatPrice,
    );

    const [currentSliderValues, setCurrentSliderValues] = useState({ min: currentMinValue, max: currentMaxValue });

    const buttonSliderProps: ButtonSliderType = {
        minValue: clippedMin,
        maxValue: clippedMax,
        step,
        currentMaxValue: Math.min(currentMaxValue, clippedMax),
        currentMinValue: Math.max(currentMinValue, clippedMin),
        setValue: setValueFn,
        enableMinValue: true,
        order: SLIDER_INCREASING,
        getValueLabel: getValueLabelFn,
        filterName: "price",
        setSliderValues: (min, max) => setCurrentSliderValues({ min, max }),
    };

    return (
        <>
            <RangeSliderInput
                initialValues={{ min: clippedMin, max: clippedMax }}
                currentValues={{ min: currentSliderValues.min, max: currentSliderValues.max }}
                initialClampValues={{ min: minValue, max: maxValue }}
                setValueFn={setValueFn}
                labels={{
                    from: minValueText,
                    to: maxValueText,
                    [unit?.useAsPrefix ? "prefix" : "suffix"]: unit?.value,
                }}
                name="price"
            />
            {isRTL ? <ButtonSliderRTL {...buttonSliderProps} /> : <ButtonSlider {...buttonSliderProps} />}
        </>
    );
};

export default PriceFilterSlider;
