import { useMemo } from "react";
import { shallowEqual, useDispatch } from "react-redux";
import type { CarFilterDispatchType } from "../../redux/store";
import { useCarFilterSelector } from "../../redux/store";
import { setPage } from "../../redux/actions/CarFiltersActions";

/**
 * @param start The start of the range.
 * @param end The end of the range.
 * @returns An array of numbers between start and end, inclusive.
 * @example
 * range(1, 5) // [1, 2, 3, 4, 5]
 */
const range = (start: number, end: number): number[] => {
    return Array.from({ length: end - start + 1 }, (_, index) => index + start);
};

export const ELLIPSIS = "ellipsis";

type NavigateToPageType = (desiredPage: number, direction?: "next" | "prev") => void;

export type UsePaginationControlsReturnType = {
    navigateToPage: (page: number) => void;
    navigateToNextPage: () => void;
    navigateToPreviousPage: () => void;
    canNavigateRight: boolean;
    canNavigateLeft: boolean;
    paginationButtons: (number | typeof ELLIPSIS)[];
};

// Constants for readability
const ACTIVE_BUFFER = 2; // Amount of pages to show on each side of the active page
const BOUNDARY_BUFFER = 1; // Amount of pages to show on each side of the first and last page
const ACTIVE_BUFFER_MULTIPLIER = 2;
const BOUNDARY_BUFFER_MULTIPLIER = 2;
const FIRST_LAST_ACTIVE_PAGES = 3;

const usePaginationControls = (): UsePaginationControlsReturnType => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const { page, totalPages } = useCarFilterSelector((state) => state.carFilters.pagination, shallowEqual);

    const canNavigateLeft = totalPages > 1 && page > 1;
    const canNavigateRight = totalPages > 1 && page < totalPages;

    const navigateToPage: NavigateToPageType = (desiredPage, direction) => {
        let newPage = desiredPage;
        if (direction === "next" && canNavigateRight) {
            newPage = page + 1;
        } else if (direction === "prev" && canNavigateLeft) {
            newPage = page - 1;
        }

        if (newPage >= 1 && newPage <= totalPages && newPage !== page) {
            dispatch(setPage(newPage));
        }
    };

    const paginationButtons = useMemo((): (number | typeof ELLIPSIS)[] => {
        const totalPageNumbers =
            ACTIVE_BUFFER * ACTIVE_BUFFER_MULTIPLIER +
            FIRST_LAST_ACTIVE_PAGES +
            BOUNDARY_BUFFER * BOUNDARY_BUFFER_MULTIPLIER;

        if (totalPageNumbers > totalPages) {
            return range(1, totalPages);
        }

        const leftPageNumber = Math.max(page - ACTIVE_BUFFER, BOUNDARY_BUFFER);
        const rightPageNumber = Math.min(page + ACTIVE_BUFFER, totalPages - BOUNDARY_BUFFER);

        const shouldShowLeftDots = leftPageNumber > ACTIVE_BUFFER + 2;
        const shouldShowRightDots = rightPageNumber < totalPages - (ACTIVE_BUFFER + 1);

        if (!shouldShowLeftDots && shouldShowRightDots) {
            const leftItemCount = ACTIVE_BUFFER * ACTIVE_BUFFER_MULTIPLIER + BOUNDARY_BUFFER + 2;

            // Rendered result will be 1 2 3 4 5 6 7 ... 10
            return [...range(1, leftItemCount), ELLIPSIS, ...range(totalPages - (BOUNDARY_BUFFER - 1), totalPages)];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            const rightItemCount = BOUNDARY_BUFFER + 1 + ACTIVE_BUFFER * ACTIVE_BUFFER_MULTIPLIER;

            // Rendered result will be 1 ... 4 5 6 7 8 9 10
            return [...range(1, BOUNDARY_BUFFER), ELLIPSIS, ...range(totalPages - rightItemCount, totalPages)];
        }

        // Rendered result will be 1 ... 3 4 5 6 7 ... 10
        return [
            ...range(1, BOUNDARY_BUFFER),
            ELLIPSIS,
            ...range(leftPageNumber, rightPageNumber),
            ELLIPSIS,
            ...range(totalPages - BOUNDARY_BUFFER + 1, totalPages),
        ];
    }, [totalPages, page]);

    return {
        navigateToPage,
        navigateToNextPage: () => navigateToPage(page + 1, "next"),
        navigateToPreviousPage: () => navigateToPage(page - 1, "prev"),
        canNavigateRight,
        canNavigateLeft,
        paginationButtons,
    };
};

export default usePaginationControls;
