import * as DOMUtils from "history/DOMUtils";
import { throttle } from "lodash";
import type { CommonSettingsType } from "../../../../common-deprecated/settings/fetchCommonSettings";
import { RetailerCarConfigTypes, RetailerIframeMessageEventType } from "./constants";
import type { RetailerModelDataType } from "../redux/reducers/RetailerSettingsReducer";
import { isRetailerApps } from "../../../../common-deprecated/settings/utils/commonSettingUtils";

export const sendRetailerActivity = throttle(() => {
    window.parent.window.postMessage({ type: RetailerIframeMessageEventType.IFrameActivity }, "*");
}, 3000);

export const getChromeVersion = (userAgent?: string): number | null => {
    if (DOMUtils.canUseDOM && !userAgent) userAgent = window.navigator.userAgent;
    if (!userAgent) return null;

    const chromeVersion = userAgent.match(/Chrom(e|ium)\/([0-9]+)\./)?.[2]; // will return undefined if not chrome
    return chromeVersion ? parseFloat(chromeVersion) || null : null;
};

export const getTizenVersion = (userAgent?: string): number | null => {
    if (DOMUtils.canUseDOM && !userAgent) userAgent = window.navigator.userAgent;
    if (!userAgent) return null;

    const chromeVersion = getChromeVersion(userAgent);
    let tizenVersion = userAgent.match(/Tizen ([0-9]+\.[0-9]+)/)?.[1]; // will return undefined if not Tizen OS
    if (!tizenVersion && chromeVersion && chromeVersion === 56) tizenVersion = "4.5"; // easy mock Tizen4 when using old chromium browser

    return tizenVersion ? parseFloat(tizenVersion) || null : null;
};

export const shouldUseToyotaRetailerApheleia = (
    modelCode: string,
    modelData: RetailerModelDataType[],
    commonSettings: CommonSettingsType,
): RetailerCarConfigTypes => {
    // This override link allows us to test on test devices before a model goes live
    const override = commonSettings.query.overrideCarConfigLink;
    if (override) return override;

    const { isStandalone } = commonSettings;
    const foundModel = modelData.find((model) => model.modelCode.toLowerCase() === modelCode.toLowerCase());
    if (
        (isStandalone || foundModel?.apheleiaLiveStatusEnabledModel) &&
        foundModel?.apheleiaLiveStatusWebsite &&
        !isRetailerApps(commonSettings) // car on display, salesman & billboard will never support apheleia
    ) {
        return foundModel.useLbxJourney ? RetailerCarConfigTypes.AphLbx : RetailerCarConfigTypes.Aph;
    }
    return RetailerCarConfigTypes.Build;
};
