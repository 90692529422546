import type { TypedUseSelectorHook } from "react-redux";
import { useSelector } from "react-redux";
import type { Dispatch, ReducersMapObject } from "redux";
import type { CommonActionsType, CommonReducerType } from "../../../common-deprecated/redux/commonStore";
import commonUtilLogic from "../../../common-deprecated/redux/logic/CommonUtilLogic";
import CommonSettingsReducer from "../../../common-deprecated/redux/reducers/CommonSettingsReducer";
import modalReducer from "../../../common-deprecated/redux/reducers/ModalReducer";
import utilReducer from "../../../common-deprecated/redux/reducers/UtilReducer";
import { getInitStoreByReducers } from "../../../common-deprecated/redux/storeHelpers";
import type { CreateLogicType } from "../../../common-deprecated/redux/typeHelpers";
import type { CompareUscSettingsActionsType } from "./actions/CompareUscSettingsActions";
import type { UsedCarCompareActionsType } from "./actions/UsedCarCompareActions";
import usedCarCompareLogic, { serverUsedCarCompareLogic } from "./logic/UsedCarCompareLogic";
import type { CompareUscSettingsType } from "./reducers/CompareUscSettingsReducer";
import CompareUscSettingsReducer from "./reducers/CompareUscSettingsReducer";
import type { UsedCarCompareReducerType } from "./reducers/UsedCarCompareReducer";
import usedCarCompareReducer from "./reducers/UsedCarCompareReducer";
import type { UsedCarCompareExternalDataReducerType } from "../../../common-deprecated/features/compare/redux/reducers/UsedCarCompareExternalDataReducer";
import usedCarCompareExternalDataReducer from "../../../common-deprecated/features/compare/redux/reducers/UsedCarCompareExternalDataReducer";

export type CompareUSCStateType = CommonReducerType & {
    usedCarCompare: UsedCarCompareReducerType;
    usedCarCompareExternalData: UsedCarCompareExternalDataReducerType;
    compareUscSettings: CompareUscSettingsType;
};

export type CompareUSCActionsType = CommonActionsType | UsedCarCompareActionsType | CompareUscSettingsActionsType;

export type CompareUSCDispatchType = Dispatch<CompareUSCActionsType>;

export type CompareUSCLogicType<T extends CompareUSCActionsType> = CreateLogicType<
    T,
    CompareUSCStateType,
    CompareUSCDispatchType
>;

export const useCompareUSCSelector: TypedUseSelectorHook<CompareUSCStateType> = useSelector;

export const compareUSCReducers: ReducersMapObject<CompareUSCStateType, any> = {
    usedCarCompare: usedCarCompareReducer,
    usedCarCompareExternalData: usedCarCompareExternalDataReducer,
    commonSettings: CommonSettingsReducer,
    compareUscSettings: CompareUscSettingsReducer,
    utils: utilReducer,
    modal: modalReducer,
};

export const compareUSCClientStore = getInitStoreByReducers(compareUSCReducers, [
    ...usedCarCompareLogic,
    ...commonUtilLogic,
]);
export const compareUSCServerStore = getInitStoreByReducers(compareUSCReducers, [...serverUsedCarCompareLogic]);
