import React, { useState } from "react";
import { useDispatch } from "react-redux";
import type { CarFilterDispatchType } from "../../../../redux/store";
import { useCarFilterSelector } from "../../../../redux/store";
import type { LocationFilterIdType } from "../../../../utils/constants/filterConstants";
import { setLocationRange } from "../../../../redux/actions/CarFiltersActions";
import { useCarFilterLabel, useCarFilterOptionalLabel } from "../../../../utils/constants/labels";
import * as LocationComboStyles from "./styles/LocationComboStyles";
import { IconChevronDown } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import LocationRangeItem from "./LocationRangeItem";

type LocationRangeType = { filterId: LocationFilterIdType };
const LocationRange = ({ filterId }: LocationRangeType): JSX.Element => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const currentRange = useCarFilterSelector((state) => state.carFilters[filterId].range?.range);
    const rangeValues = useCarFilterSelector((state) => state.carFilters[filterId].rangeValues);
    const [radiusLabel, nationalLabel, locationRangeLabel] = useCarFilterLabel([
        "carFilterLocationRadius",
        "carFilterLocationNational",
        "carFilterLocationRadiusLabel",
    ]);
    const [disclaimerTitleLabel, disclaimerLabel] = useCarFilterOptionalLabel([
        "carFilterLocationRadiusDisclaimerTitle",
        "carFilterLocationRadiusDisclaimer",
    ]);

    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [disclaimerDropdownOpened, setDisclaimerDropdownOpened] = useState(false);

    const currentDealerCount = rangeValues.find((range) => range.km === currentRange)?.dealerCount || 0;

    return (
        <LocationComboStyles.Wrapper>
            <LocationComboStyles.Label id="locationcombo-label">{locationRangeLabel}</LocationComboStyles.Label>
            <LocationComboStyles.ComboWrapper>
                <LocationComboStyles.ComboInput
                    aria-controls="listbox-locationrange"
                    aria-expanded={dropdownOpened}
                    aria-haspopup="listbox"
                    aria-labelledby="locationcombo-label"
                    aria-activedescendant={
                        currentRange === 0 ? nationalLabel : `${String(currentRange)} ${radiusLabel}`
                    }
                    role="combobox"
                    tabIndex={0}
                    onClick={() => {
                        setDropdownOpened(!dropdownOpened);
                        setDisclaimerDropdownOpened(false);
                    }}
                >
                    <LocationRangeItem range={currentRange} dealerCount={currentDealerCount} />
                    <LocationComboStyles.ComboInputIcon>
                        <IconChevronDown />
                    </LocationComboStyles.ComboInputIcon>
                </LocationComboStyles.ComboInput>
                <LocationComboStyles.ComboMenu
                    role="listbox"
                    id="listbox-locationrange"
                    aria-labelledby="locationcombo-label"
                    tabIndex={-1}
                >
                    {rangeValues.map(
                        // eslint-disable-next-line id-length
                        ({ km, dealerCount }) => {
                            // We only want to render the option if it's not the current range
                            if (km === currentRange) return null;

                            return (
                                <LocationRangeItem
                                    key={km}
                                    range={km}
                                    dealerCount={dealerCount}
                                    // No bottom border for the last item
                                    bottomBorder={km !== rangeValues[rangeValues.length - 1].km}
                                    onClick={() => {
                                        dispatch(setLocationRange(filterId, km));
                                        setDropdownOpened(false);
                                    }}
                                    onKeyDown={(event) => {
                                        if (event.key === "Enter") {
                                            dispatch(setLocationRange(filterId, km));
                                            setDropdownOpened(false);
                                        }
                                    }}
                                />
                            );
                        },
                    )}
                </LocationComboStyles.ComboMenu>
            </LocationComboStyles.ComboWrapper>
            {disclaimerTitleLabel && disclaimerLabel && (
                <>
                    <LocationComboStyles.CollapseButton
                        type="button"
                        onClick={() => setDisclaimerDropdownOpened(!disclaimerDropdownOpened)}
                        aria-expanded={disclaimerDropdownOpened}
                        aria-controls="location-disclaimer"
                    >
                        <span>{disclaimerTitleLabel}</span>
                        <span>
                            <IconChevronDown />
                        </span>
                    </LocationComboStyles.CollapseButton>
                    <LocationComboStyles.CollapseContent id="location-disclaimer">
                        {disclaimerLabel}
                    </LocationComboStyles.CollapseContent>
                </>
            )}
        </LocationComboStyles.Wrapper>
    );
};

export default LocationRange;
