import type { FC } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import FilterResultCount from "./FilterResultCount";
import { showModal } from "../../../../../../common-deprecated/redux/actions/ModalActions";
import type { CarFilterDispatchType } from "../../../../redux/store";
import { MODAL_FILTER_CAR_FILTERS, MODAL_FILTER_SORT_FILTERS } from "../../../../utils/modalConstants";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import { useMobileSortFilters } from "../../../../hooks/active-filters/useSortFilters";
import { IconFilters, IconSort } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { LineClamp } from "../../../../../../common-deprecated/styles/v2/toyota/utilities/LineClamp";
import * as Styles from "./MobileStaticFilterBar.styles";

const MobileStaticFilterBar: FC = () => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const [allFiltersLabel, sortFiltersLabel] = useCarFilterLabel(["carFilterAllFilters", "carFilterSort"]);
    const hasSortFilters = useMobileSortFilters();

    return (
        <Styles.Wrapper>
            <Styles.ButtonGroup>
                <Styles.Button
                    type="button"
                    onClick={() => {
                        dispatch(showModal({ type: MODAL_FILTER_CAR_FILTERS }));
                    }}
                >
                    <LineClamp>{allFiltersLabel}</LineClamp>
                    <IconFilters />
                </Styles.Button>
                {hasSortFilters && (
                    <Styles.Button
                        type="button"
                        onClick={() => {
                            dispatch(showModal({ type: MODAL_FILTER_SORT_FILTERS }));
                        }}
                    >
                        <LineClamp>{sortFiltersLabel}</LineClamp>
                        <IconSort />
                    </Styles.Button>
                )}
            </Styles.ButtonGroup>
            <Styles.FilterResultCount>
                <FilterResultCount show />
            </Styles.FilterResultCount>
        </Styles.Wrapper>
    );
};

export default MobileStaticFilterBar;
