import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { MODAL_CACHE_CLEAR, MODAL_REQUEST_LOG, MODAL_VERSION, MODAL_TOOLS } from "../utils/modalConstants";
import type { CommonDispatchType } from "../redux/commonStore";
import { useCommonSelector } from "../redux/commonStore";
import { hideModal, showModal } from "../redux/actions/ModalActions";
import { EnvironmentEnum } from "../utils";
import type { CommonSettingsType } from "../settings/fetchCommonSettings";
import type { ToolsModalItemType, QueryBuilderStateType } from "../features/new-cars/debug-modal/components/ToolsModal";
import type { ModalsType } from "../types/CommonTypes";

export type AdditionalModalsType = { key: string; settings: ModalsType }[];

const useStatus = (
    commonSettings: CommonSettingsType,
    componentName: string,
    extraToolLinks: ToolsModalItemType[] = [],
    additionalModals: AdditionalModalsType = [],
    extraQueryBuilderOptions?: QueryBuilderStateType,
): void => {
    const dispatch = useDispatch<CommonDispatchType>();
    const modals = useCommonSelector((state) => state.modal.modals);

    const [spaceDown, setSpaceDown] = useState<boolean>(false);

    const { environment } = commonSettings;
    const currentModal = modals.length ? modals[modals.length - 1].settings!.type : "";
    const additionalModalsKey = additionalModals.map((additionalModal) => additionalModal.key).join();

    useEffect(() => {
        // Show the given modalType, or hide it if it is already shown, don't do anything when it's in the stack somewhere.
        const toggleModal = (modalSettings: ModalsType): void => {
            if (spaceDown) {
                const alreadyActive = !!modals.filter(
                    (modal) => modal.settings?.type === modalSettings.type && modal.show,
                ).length;
                if (alreadyActive) dispatch(hideModal(modalSettings.type));
                else if (!alreadyActive) {
                    dispatch(showModal(modalSettings));
                }
            }
        };

        const toggleKey = (event: KeyboardEvent): void => {
            if (["input", "textarea"].includes(document.activeElement?.tagName.toLowerCase() || "")) return;

            const keyDown = event.type === "keydown";

            switch (event.key) {
                case " ":
                    setSpaceDown(keyDown);
                    break;

                case "v":
                    toggleModal({ type: MODAL_VERSION });
                    break;

                case "r":
                    toggleModal({ type: MODAL_REQUEST_LOG });
                    break;

                case "t":
                    toggleModal({
                        type: MODAL_TOOLS,
                        componentName,
                        extraItems: extraToolLinks,
                        extraQueryBuilderOptions,
                    });
                    break;

                case "c":
                    if (environment !== EnvironmentEnum.Production) toggleModal({ type: MODAL_CACHE_CLEAR });
                    break;
            }

            additionalModals.forEach(({ key, settings }) => {
                if (key === event.key) toggleModal(settings);
            });
        };
        window.addEventListener("keydown", toggleKey);

        // Listen to keyup to reset spacedown state.
        const toggleSpace = (event: KeyboardEvent): void => {
            if (event.key === " " && spaceDown) setSpaceDown(false);
        };
        window.addEventListener("keyup", toggleSpace);

        return () => {
            window.removeEventListener("keydown", toggleKey);
            window.removeEventListener("keyup", toggleSpace);
        };
    }, [spaceDown, currentModal, additionalModalsKey]);
};

export default useStatus;
