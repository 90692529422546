import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import type { CarFilterIdType } from "../../../../../shared-logic/types/UscCommonTypes";
import * as Styles from "./styles/ActiveFilterSliderStyles";
import useSlider from "../../../../../../common-deprecated/hooks/useSlider";
import ActiveFilterSliderPill from "./ActiveFilterSliderPill";
import useActiveFilterSlider from "../../../../../../shared-logic/features/filters/hooks/useActiveFilterSlider";
import type { CarFilterDispatchType } from "../../../../redux/store";
import { useCarFilterSelector } from "../../../../redux/store";
import { clearAllFilters } from "../../../../utils/filters";
import { useCarFilterLabel } from "../../../../utils/constants/labels";

type ActiveFilterSliderType = { activeFilters: CarFilterIdType[]; activeFiltersKey: string };

/**
 * Slider that contains the small slider pills for active filters.
 */
const ActiveFilterSlider = ({ activeFilters, activeFiltersKey }: ActiveFilterSliderType): JSX.Element => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const carFilters = useCarFilterSelector((state) => state.carFilters);
    const clearFiltersLabel = useCarFilterLabel("carFilterClearAllFilters");
    const sliderRef = useRef<HTMLDivElement>(null);
    const viewportRef = useRef<HTMLDivElement>(null);
    const prerenderRef = useRef<HTMLDivElement>(null);
    const { sliderWidth, viewportWidth, updateWidth, enableSlider, sliderFilters } = useActiveFilterSlider(
        prerenderRef,
        viewportRef,
        activeFilters,
        activeFiltersKey,
    );

    // Retrieve all props for slider usage.
    const sliderProps = useSlider(sliderWidth, viewportWidth);
    const { startTouchDrag, startMouseDrag, sliderX, enableDrag, isScrolling, enableCssTransition } = sliderProps;
    const { onTransitionEnd } = sliderProps;

    return (
        <>
            <Styles.Slider>
                <Styles.SliderViewport ref={viewportRef}>
                    <Styles.Track
                        ref={sliderRef}
                        width={sliderWidth}
                        left={enableDrag ? sliderX : 0}
                        enableTransition={enableDrag ? enableCssTransition : false}
                        onTransitionEnd={onTransitionEnd}
                        onTouchStart={enableDrag && enableSlider ? startTouchDrag : undefined}
                        onMouseDown={enableDrag && enableSlider ? startMouseDrag : undefined}
                        onClick={(event) => {
                            // Prevent the click from working when the slider is scrolling.
                            if (isScrolling.current) {
                                event.preventDefault();
                                event.stopPropagation();
                            }
                        }}
                    >
                        {sliderFilters.map((filterId) => (
                            <ActiveFilterSliderPill key={filterId} filterId={filterId} />
                        ))}
                    </Styles.Track>
                </Styles.SliderViewport>
            </Styles.Slider>
            <Styles.ClearButton type="button" onClick={() => clearAllFilters(dispatch, carFilters)}>
                {clearFiltersLabel}
            </Styles.ClearButton>
            {/* Hidden and heightless container used to render the slider items to get their width. */}
            <Styles.Prerender ref={prerenderRef}>
                {activeFilters.map((filterId) => (
                    <ActiveFilterSliderPill key={filterId} filterId={filterId} update={updateWidth} />
                ))}
            </Styles.Prerender>
        </>
    );
};

export default ActiveFilterSlider;
