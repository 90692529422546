import type { CSSProperties, FC, FunctionComponent, PropsWithChildren } from "react";
import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { clearModal, hideModal } from "../redux/actions/ModalActions";
import { useShowAfterFirstRender } from "../hooks";
import type { ModalIdsType, ModalMapType, ModalsType, ModalViewType } from "../types/CommonTypes";
import { MAIN_NAV_CLASSNAME_TOYOTA } from "../constants";
import UnsetZIndexHelper from "../components/UnsetZIndexHelper";
import { useCommonSelector } from "../redux/commonStore";
import Debug from "../Debug";

// ----------------------------------------------------------------------
// ModalContainer Rendering
// ----------------------------------------------------------------------

type ModalBaseContainerPropsType = {
    getModalComponent: (type: ModalIdsType) => FunctionComponent<ModalViewType<ModalsType>> | null;
    className?: string;
    animateClosing: boolean;
    style?: CSSProperties;
};

const ModalContainer = (props: ModalBaseContainerPropsType): JSX.Element | null => {
    const { getModalComponent, className, animateClosing, style } = props;
    const { modals } = useCommonSelector((state) => state.modal);
    const dispatch = useDispatch();

    // ℹ️ For people arriving here looking for modal trigger code, please refer to the useStatus hook ℹ️
    if (!modals.length) return null;

    const modalsShown = modals.some((modal) => modal.show);

    return (
        <div className={className} style={style}>
            <UnsetZIndexHelper enabled={modalsShown} selectors={[MAIN_NAV_CLASSNAME_TOYOTA, ".fluid.scroll"]} />
            {modals.map((modal) => {
                if (!modal.settings?.type) return null;
                const Modal = getModalComponent(modal.settings.type);

                if (!Modal) return null;

                return (
                    <Modal
                        key={`${modal.settings.type}-${modal.modalIndex}`}
                        show={modal.show}
                        modalSettings={modal.settings}
                        close={() => {
                            /**
                             * To make the ModalProvider backwards compatible the animateClosing property has been added.
                             * For more info on the difference between the two see the descriptions of the actions below.
                             */
                            if (animateClosing) {
                                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                                dispatch(hideModal(modal.settings?.type!));
                            } else {
                                // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
                                dispatch(clearModal(modal.settings?.type!));
                            }
                        }}
                    />
                );
            })}
        </div>
    );
};

// ----------------------------------------------------------------------
// ModalContext Provider
// ----------------------------------------------------------------------

type ModalContextType = {
    addToModalMap?: (newModals: ModalMapType) => void;
    setStyle: (newStyle: CSSProperties) => void;
};

export const ModalContext = createContext<ModalContextType>({ setStyle: () => undefined });

export const ModalProvider: FC<PropsWithChildren<{ className?: string; animateClosing?: boolean }>> = (props) => {
    const { className, children, animateClosing = false } = props;

    const [style, setStyle] = useState<CSSProperties | undefined>(undefined);
    const updateStyle = useCallback((newStyle: CSSProperties) => {
        setStyle(newStyle);
    }, []);

    const modalMap = useRef<ModalMapType>({});
    const addToModalMap = useCallback((newModals: ModalMapType): void => {
        modalMap.current = { ...modalMap.current, ...newModals };
    }, []);

    const getModalComponent = useCallback((type: ModalIdsType) => {
        if (!modalMap.current?.[type]) {
            Debug.warn(`Attempting to open modal ${type} but it's not present in ModalContext`);
            return null;
        }
        return modalMap.current[type]!;
    }, []);

    // As the current modal implementation relies on hooks we can't render modals during SSR as we only know about each modal after the first render.
    // There is various server-side logic which triggers modals so instead of removing those it makes more sense to only render them after the initial SSR.
    const show = useShowAfterFirstRender();

    return (
        <ModalContext.Provider value={{ addToModalMap, setStyle: updateStyle }}>
            {children}
            {/* TODO: combine these 2 components once all other components are refactored? */}
            {show && (
                <ModalContainer
                    className={className}
                    getModalComponent={getModalComponent}
                    style={style}
                    animateClosing={animateClosing}
                />
            )}
        </ModalContext.Provider>
    );
};

/**
 * Use this hook in the root React-component of every OR-component that needs modals.
 * We register modal components this way so dynamically imported modals can register new modals on runtime.
 * If there are no OR-component specific modals it should still be called with either the brand's common modalMap.
 */
export const useModalMap = (newModals: ModalMapType): void => {
    const modal = useContext(ModalContext);
    useEffect(() => {
        // Extra layer of safety in case the component is used without the provider
        if (modal?.addToModalMap) modal.addToModalMap(newModals);
    }, [modal, newModals]);
};
