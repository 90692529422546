import styled, { css } from "styled-components";
import type { SpaceProps } from "styled-system";
import { space } from "styled-system";
import { getDirection, getBreakpoint } from "../../../../themes/common";
import { theme } from "../theme/toyotaTheme";

const CUSTOM_INPUT_WIDTH = 20;
const CUSTOM_INPUT_HEIGHT = 20;

const Input = styled.div``;

const radioLeftStyles = css`
    padding-${getDirection("left")}: 48px;
    &::before {
        ${getDirection("left")}: 0;
    }
    &::after {
        ${getDirection("left")}: 10px;
    }
`;

const radioRightStyles = css`
    padding-${getDirection("right")}: 48px;
    &::before {
        ${getDirection("left")}: auto;
        ${getDirection("right")}: 0;
    }
    &::after {
        ${getDirection("left")}: auto;
        ${getDirection("right")}: 10px;
    }
`;

export const Radio = styled.label.attrs(() => ({
    className: "custom-control-label",
}))<{ checked?: boolean; disabled?: boolean; radioRight?: boolean }>`
    position: relative;
    min-width: 32px;
    min-height: 32px;
    margin: 0 0 ${theme.space[2]}px 0;
    font-family: ${theme.fonts.base};
    transition: opacity 0.3s;
    cursor: pointer;
    opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};

    &::before {
        position: absolute;
        top: -3px;
        width: 32px;
        height: 32px;
        content: "";
        background: ${({ checked }) => (checked ? theme.colors.primaryBlack : theme.colors.primaryWhite)};
        border: 1px solid ${({ checked }) => (checked ? theme.colors.primaryBlack : theme.colors.grey7)};
        border-radius: 50%;
    }

    &::after {
        display: ${({ checked }) => (checked ? "block" : "none")};
        position: absolute;
        top: 9px;
        width: 11px;
        height: 8px;
        content: "";
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDExIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxsaW5lIHgxPSIwLjczMDUyNSIgeTE9IjUuMjY5MDgiIHgyPSIzLjkzMDUyIiB5Mj0iOC40NjkwOCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPGxpbmUgeDE9IjIuODcwMDYiIHkxPSI4LjQ2OTY3IiB4Mj0iMTAuMDcwMSIgeTI9IjEuMjY5NjciIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41Ii8+Cjwvc3ZnPgo=");
        background-size: 11px 8px;
    }
    ${({ disabled }) =>
        disabled &&
        css`
            &::before {
                border-color: #c7cfd7;
                background-color: ${theme.colors.grey1};
            }
        `}
    ${({ radioRight }) =>
        radioRight
            ? css`
                  @media ${getBreakpoint("down", "sm")} {
                      ${radioLeftStyles};
                  }
                  @media ${getBreakpoint("up", "sm")} {
                      ${radioRightStyles};
                  }
              `
            : radioLeftStyles};
`;

export const Label = styled.label`
    font-weight: 400;
    cursor: pointer;
`;

export const Checkbox = styled.label.attrs(() => ({
    className: "checkbox-label",
}))<{ disabled?: boolean; large?: boolean }>`
    display: inline;
    position: relative;
    margin: 0;
    padding: 0;
    padding-${getDirection("left")}: 36px;
    font-family: ${theme.fonts.base};
    font-size: 1.6rem;
    line-height: 2.4rem;
    border-radius: 50%;
    cursor: pointer;

    /* checkbox square */
    &::before {
        position: absolute;
        top: 2px;
        ${getDirection("left")}: 0;
        width: ${CUSTOM_INPUT_WIDTH}px;
        height: ${CUSTOM_INPUT_HEIGHT}px;
        content: "";
        background-color: ${theme.colors.primaryWhite};
        border: 1px solid ${theme.colors.grey3};
        border-radius: 50% !important;
        box-sizing: border-box;
    }
    
`;

type InputType = {
    variant: "checkbox" | "radio";
    large?: boolean;
};

export const Wrapper = styled.div.attrs<InputType>((props) => ({
    className: `custom-${props.variant}`,
}))<SpaceProps & InputType>`
    ${space};

    input {
        position: absolute !important;
        width: 1px;
        height: 1px;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
    }

    input:focus-visible ~ ${Checkbox} {
        border-radius: 0;
        outline: 2px solid ${theme.colors.outline} !important;
        outline-offset: 2px;
        @media (prefers-reduced-motion: no-preference) {
            transition: outline 0.3s ease-out, outline-offset 0.3s ease-out;
        }
    }

    input:checked ~ ${Checkbox} {
        &::before {
            position: absolute;
            top: 2px;
            ${getDirection("left")}: 0;
            width: ${CUSTOM_INPUT_WIDTH}px;
            height: ${CUSTOM_INPUT_HEIGHT}px;
            color: ${theme.colors.primaryWhite};
            content: "";
            background-color: ${theme.colors.primaryBlack};
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDExIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8bGluZSB4MT0iMC43MzA1MjUiIHkxPSI1LjI2OTIzIiB4Mj0iMy45MzA1MiIgeTI9IjguNDY5MjMiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41Ii8+CiAgICA8bGluZSB4MT0iMi44NzAwNiIgeTE9IjguNDY5NjciIHgyPSIxMC4wNzAxIiB5Mj0iMS4yNjk2NyIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPC9zdmc+Cg==");
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    ${({ large }) =>
        large &&
        css`
            ${Checkbox} {
                display: inline-block;
                padding-top: 5px;
                padding-${getDirection("left")}: 44px;
                        
                &::before {
                    width: 32px;
                    height: 32px;
                }
            };

            input:checked ~ ${Checkbox} {
                &::before {
                    width: 32px;
                    height: 32px;
                }
            }

            input:focus-visible ~ ${Checkbox} {
                height: 36px;
            }
   
        
        `};
`;

export default Input;
