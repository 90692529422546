import React from "react";
import { useDispatch } from "react-redux";
import type { CarFilterDispatchType } from "../../../../redux/store";
import { useCarFilterSelector } from "../../../../redux/store";
import { IconFilters, IconPlus, IconClose } from "../../../../../../common-deprecated/styles/v2/toyota/globals/Icon";
import { useCarFilterLabel } from "../../../../utils/constants/labels";
import SortFilters from "./SortFilters";
import { showMoreFilters } from "../../../../redux/actions/CarFiltersActions";
import FilterResultCount from "./FilterResultCount";
import { Ellipsis } from "../../../../../../common-deprecated/styles/v2/toyota/utilities/Ellipsis";
import * as Styles from "./styles/StaticFilterBarStyles";

type StaticFilterBarType = { isFixed: boolean };

const StaticFilterBar = ({ isFixed }: StaticFilterBarType): JSX.Element => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const showFilters = useCarFilterSelector((state) => state.carFilters.showMoreFilters);
    const vehicleAggregationsActive = useCarFilterSelector(
        ({ carFilterSettings, carFilters }) => carFilterSettings.enableVehicleAggregations[carFilters.currentFilter],
    );

    const allFiltersLabel = useCarFilterLabel("carFilterAllFilters");

    const shouldHideResultCount = vehicleAggregationsActive;

    return (
        <Styles.Border>
            <Styles.Wrapper maxWidth="1600px">
                <Styles.Inner>
                    <div>
                        <Styles.Button
                            type="button"
                            onClick={() => dispatch(showMoreFilters(!showFilters))}
                            isActive={showFilters}
                        >
                            <div>
                                <IconFilters aria-hidden="true" />
                                <Ellipsis maxWidth={{ _: "100%" }}>{allFiltersLabel}</Ellipsis>
                            </div>
                            {showFilters ? <IconClose aria-hidden="true" /> : <IconPlus aria-hidden="true" />}
                        </Styles.Button>
                    </div>

                    {!shouldHideResultCount && (
                        <Styles.FilterResultCount>
                            <FilterResultCount show />
                        </Styles.FilterResultCount>
                    )}

                    <SortFilters />
                </Styles.Inner>
            </Styles.Wrapper>
        </Styles.Border>
    );
};

export default StaticFilterBar;
