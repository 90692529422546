import type { UsedCarCompareExternalDataActionsType } from "../../../../common-deprecated/features/compare/redux/actions/UsedCarCompareExternalDataActions";

import type { SpecItemType } from "../../../../common-deprecated/features/compare/utils/newCarCompareTypes";
import type { LoadingUsedCompareCarType } from "../../../../common-deprecated/features/compare/utils/usedCarCompareTypes";
import type { UsedParsedEquipment } from "../../utils/compareTypes";

export const USED_CAR_COMPARE_SET_CARS_TO_ADD = "usedCarCompare/setCarsToAdd" as const;
export const USED_CAR_COMPARE_SET_DATA = "usedCarCompare/setParsedData" as const;

// Only used in logic
export type UsedCarCompareSetCarsToAddType = {
    type: typeof USED_CAR_COMPARE_SET_CARS_TO_ADD;
    cars: LoadingUsedCompareCarType[];
};
export const usedCompareSetCarsToAdd = (cars: LoadingUsedCompareCarType[]): UsedCarCompareSetCarsToAddType => ({
    type: USED_CAR_COMPARE_SET_CARS_TO_ADD,
    cars,
});

export type UsedCarCompareSetParsedCarsDataType = {
    type: typeof USED_CAR_COMPARE_SET_DATA;
    specs: SpecItemType[];
    equipment: UsedParsedEquipment[];
};

export const usedCompareSetParsedData = (
    specs: SpecItemType[],
    equipment: UsedParsedEquipment[],
): UsedCarCompareSetParsedCarsDataType => ({
    type: USED_CAR_COMPARE_SET_DATA,
    specs,
    equipment,
});

export type UsedCarCompareActionsType =
    | UsedCarCompareSetCarsToAddType
    | UsedCarCompareSetParsedCarsDataType
    | UsedCarCompareExternalDataActionsType;
