import type { MouseEventHandler, TouchEventHandler, RefObject, KeyboardEventHandler, KeyboardEvent } from "react";
import { useRef, useState, useEffect, useCallback } from "react";
import { ResizeObserver } from "@juggle/resize-observer"; // Not yet supported on Tizen 4, used for retailer screens
import type { SliderButtonType, SliderValueOrderType } from "../utils/constants/filterConfigConstants";
import { SLIDER_DECREASING } from "../utils/constants/filterConfigConstants";
import { getRoundedFilterValue } from "../../../../used-stock-cars/car-filter/utils/filters";
import type { PosType } from "../../../../common-deprecated/types/CommonTypes";
import { getMousePosition, getTouchPosition } from "../utils/helpers";
import { getTizenVersion } from "../../retailer/utils/utils";
import { useDebouncedEffect } from "../../../../common-deprecated/hooks";

type DataType = {
    getValueFromXPos: (xPosition: number) => number;
    getOnMouseDown: (buttonPosition: SliderButtonType) => MouseEventHandler;
    getOnTouchStart: (buttonPosition: SliderButtonType) => TouchEventHandler;
    getOnKeyDown: (buttonPosition: SliderButtonType) => KeyboardEventHandler;
    xPositionLeft: number;
    xPositionRight: number;
};

const useButtonSlider = (
    sliderBarRef: RefObject<HTMLDivElement>,
    order: SliderValueOrderType,
    maxValue: number,
    minValue: number,
    step: number,
    setValue: (value: number, type: SliderButtonType) => void,
    currentMaxValue: number,
    currentMinValue: number = 0,
    buttonSize: number,
    enableMinValue?: boolean,
): DataType => {
    const initialMovePosition = useRef<PosType>({ x: 0, y: 0 });
    const initialScrollPosition = useRef<PosType>({ x: 0, y: 0 });

    const [isInteracting, setIsInteracting] = useState(false);
    const [xPositionLeft, setXPositionLeft] = useState<number>(0);
    const [xPositionRight, setXPositionRight] = useState<number>(0);
    const [isSliding, setIsSliding] = useState<SliderButtonType | null>(null);
    // True when the user is using the keyboard arrows instead of the mouse to update the slider
    const [isUsingArrows, setIsUsingArrows] = useState<SliderButtonType | null>(null);

    useDebouncedEffect(() => endInteraction(isSliding), (getTizenVersion() ?? Infinity) < 5 ? 250 : 0, [
        isSliding,
        xPositionLeft,
        isInteracting,
    ]);

    // ----------------------------------------------------------------------
    // Resize behaviour
    //
    // Logic is executed once after initial render to correctly set the initial X positions
    // and when the slider changes size (window resize or mobile panel collapsing)
    // ----------------------------------------------------------------------
    useEffect(() => {
        if (!sliderBarRef.current) return;

        const observer = new ResizeObserver(([{ target }]): void => {
            const rect = target.getBoundingClientRect();

            // Calculate the difference between the maxValue and the min value. This is the range of selectable values.
            const valueRange = maxValue - minValue;

            // Get the percentage of the max and min value. This is needed to convert the position of the button and width of the track to pixels.
            // Note that minValue is subtracted as that makes calculating the percentages based on the value range easier.
            let currentMaxPercentage = (currentMaxValue - minValue) / (valueRange / 100);
            const currentMinPercentage = (currentMinValue - minValue) / (valueRange / 100);

            // Invert percentages if the order is decreasing.
            // This is currently only relevant for single value sliders.
            if (order === SLIDER_DECREASING) currentMaxPercentage = 100 - currentMaxPercentage;

            // Get the pixel position of the left and right button, based on the boundaries of the container and the calculated percentages.
            const newXPositionRight = ((rect.right - rect.left) / 100) * currentMaxPercentage;
            const newXPositionLeft = ((rect.right - rect.left) / 100) * currentMinPercentage;

            setXPositionRight(newXPositionRight);
            setXPositionLeft(newXPositionLeft);
        });

        observer.observe(sliderBarRef.current);
        return () => observer.disconnect(); // eslint-disable-line consistent-return
    }, [currentMaxValue, currentMinValue, maxValue, minValue, order, sliderBarRef]);

    // ----------------------------------------------------------------------
    // Value calculation logic
    //
    // Functions used to map the value to a handle position and inverse.
    // ----------------------------------------------------------------------
    const getValueFromXPos = useCallback(
        (xPosition: number): number => {
            if (!sliderBarRef.current) return 0;

            const rect = sliderBarRef.current.getBoundingClientRect();
            const maxX = rect.right;
            const minX = rect.left;

            // Calculate current position percentage.
            let currentPosPercentage = xPosition / ((maxX - minX) / 100);

            // If the slider is decreasing 100% is left instead of right. Invert percentage.
            if (order === SLIDER_DECREASING) currentPosPercentage = 100 - currentPosPercentage;

            // Calculate the value for this filter based on the filled percentage of the slider.
            const unRoundedValue = currentPosPercentage * (Math.abs(maxValue - minValue) / 100) + minValue;
            if (step < 1) {
                // Round the new value to the nearest .5 decimal, this means the smallest step we support is .5 for now
                // This minimum rounding can be lowered more if necessary but if the value is too accurate we can have render issues.
                const newValue = Number((Math.round(unRoundedValue * 2) / 2).toFixed(1));
                return getRoundedFilterValue(newValue, minValue, maxValue, step);
            } else {
                return getRoundedFilterValue(unRoundedValue, minValue, maxValue, step);
            }
        },
        [maxValue, minValue, order, step, sliderBarRef],
    );

    const getXPosFromValue = useCallback(
        (value: number): number => {
            if (!sliderBarRef.current) return 0;

            const rect = sliderBarRef.current.getBoundingClientRect();
            const maxX = rect.right;
            const minX = rect.left;

            // Force the x position to the most left or right position if the value is min/max.
            // Take slider order into account as the min or max can be a different side.
            if (value === (order !== SLIDER_DECREASING ? minValue : maxValue)) return 0;
            if (value === (order !== SLIDER_DECREASING ? maxValue : minValue)) return maxX - minX;

            // Calculate current value percentage.
            let currentPosPercentage = (value - minValue) / ((maxValue - minValue) / 100);

            // If the slider is decreasing 100% is left instead of right. Invert percentage.
            if (order === SLIDER_DECREASING) currentPosPercentage = 100 - currentPosPercentage;

            // Calculate the xPosition for this filter based on the filled percentage of the slider.
            return Math.round(currentPosPercentage * (Math.abs(maxX - minX) / 100));
        },
        [maxValue, minValue, order, sliderBarRef],
    );

    // If we receive a new current min/max value by props we need to check if the slider positioning is still correct.
    // If the current x position doesn't match the new prop, update the x position as redux state takes precedence.
    const prevMaxValue = useRef<number>(currentMaxValue);
    const prevMinValue = useRef<number>(currentMinValue);
    useEffect(() => {
        if (prevMaxValue.current !== currentMaxValue) {
            const xPosValue = getValueFromXPos(xPositionRight);
            if (xPosValue !== currentMaxValue) setXPositionRight(getXPosFromValue(currentMaxValue));
        }

        if (prevMinValue.current !== currentMinValue) {
            const xPosValue = getValueFromXPos(xPositionLeft);
            if (xPosValue !== currentMinValue) setXPositionLeft(getXPosFromValue(currentMinValue));
        }

        prevMinValue.current = currentMinValue;
        prevMaxValue.current = currentMaxValue;
    }, [currentMaxValue, currentMinValue, xPositionRight, xPositionLeft, getValueFromXPos, getXPosFromValue]);

    // ----------------------------------------------------------------------
    // Move interaction
    // ----------------------------------------------------------------------
    const moveInteraction = useCallback(
        (newPosition: PosType, buttonType: SliderButtonType): void => {
            setIsInteracting(true);
            window.requestAnimationFrame(() => {
                const deltaX = newPosition.x - initialMovePosition.current.x;
                const deltaY = newPosition.y - initialMovePosition.current.y;

                if (Math.abs(deltaX) > 3 && sliderBarRef.current) {
                    const rect = sliderBarRef.current.getBoundingClientRect();
                    const maxRightPosition = rect.right - rect.left;
                    let setPosition = newPosition.x - rect.left;

                    // Prevent the button of going outside of the max and min boundaries.
                    if (setPosition >= maxRightPosition) setPosition = maxRightPosition;
                    if (setPosition <= 0) setPosition = 0;
                    setPosition = Math.round(setPosition);
                    if (buttonType === "min" && setPosition !== xPositionLeft) setXPositionLeft(setPosition);
                    if (buttonType === "max" && setPosition !== xPositionRight) setXPositionRight(setPosition);
                }

                // Prevent accidental vertical scrolling on mobile.
                if (Math.abs(deltaY) > 25 && Math.abs(deltaY) > Math.abs(deltaX)) {
                    window.scrollTo(initialScrollPosition.current.x - deltaX, initialScrollPosition.current.y - deltaY);
                }
            });
        },
        [sliderBarRef, xPositionLeft, xPositionRight],
    );

    // ----------------------------------------------------------------------
    // Event handlers
    // ----------------------------------------------------------------------
    // Move event
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isSliding) {
            const mouseMove = (evt: MouseEvent): void => moveInteraction(getMousePosition(evt), isSliding);
            const touchMove = (evt: TouchEvent): void => moveInteraction(getTouchPosition(evt), isSliding);

            document.addEventListener("mousemove", mouseMove);
            document.addEventListener("touchmove", touchMove);

            return () => {
                document.removeEventListener("mousemove", mouseMove);
                document.removeEventListener("touchmove", touchMove);
            };
        }
    }, [isSliding, moveInteraction]);

    // Listen to whether the user is interacting with the screen
    useEffect(() => {
        document.addEventListener("mousedown", () => setIsInteracting(true));
        document.addEventListener("touchstart", () => setIsInteracting(true));
        document.addEventListener("mouseup", () => setIsInteracting(false));
        document.addEventListener("touchend", () => setIsInteracting(false));

        return () => {
            document.removeEventListener("mousedown", () => setIsInteracting(true));
            document.removeEventListener("touchstart", () => setIsInteracting(true));
            document.removeEventListener("mouseup", () => setIsInteracting(false));
            document.removeEventListener("touchend", () => setIsInteracting(false));
        };
    }, []);

    // Enter event triggers updating the filter values when the user is using the keyboard arrows instead of the mouse to update the slider
    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (isUsingArrows) {
            const keyPressListener = (event: globalThis.KeyboardEvent) => {
                if (event.key === "Enter") {
                    endInteraction(isUsingArrows);
                }
            };
            document.addEventListener("keypress", keyPressListener, { once: true });

            return () => {
                document.removeEventListener("keypress", keyPressListener);
            };
        }
    }, [getValueFromXPos, isUsingArrows, setValue, xPositionLeft, xPositionRight]);

    const getOnMouseDown = useCallback(
        (buttonPosition: SliderButtonType): MouseEventHandler =>
            (event) => {
                initialMovePosition.current = getMousePosition(event);
                initialScrollPosition.current = { x: window.pageXOffset, y: window.pageYOffset };
                setIsSliding(buttonPosition);
            },
        [],
    );

    const getOnKeyDown = useCallback(
        (buttonPosition: SliderButtonType) => (event: KeyboardEvent<HTMLButtonElement>) => {
            const currentPosition = (event.target as HTMLButtonElement).getBoundingClientRect();
            if (event.key === "ArrowLeft") {
                moveInteraction({ ...currentPosition, x: currentPosition.x - 5 }, buttonPosition);
                setIsUsingArrows(buttonPosition);
            }
            if (event.key === "ArrowRight") {
                moveInteraction({ ...currentPosition, x: currentPosition.x + 5 + buttonSize }, buttonPosition);
                setIsUsingArrows(buttonPosition);
            }
        },
        [xPositionLeft, xPositionRight],
    );

    const getOnTouchStart = useCallback(
        (buttonPosition: SliderButtonType): TouchEventHandler =>
            (event) => {
                initialMovePosition.current = getTouchPosition(event);
                initialScrollPosition.current = { x: window.pageXOffset, y: window.pageYOffset };
                setIsSliding(buttonPosition);
            },
        [],
    );

    const validateHandlePositions = (
        interactingHandle: SliderButtonType,
    ): { handle: SliderButtonType; value: number; swapValue: number; shouldSwap: boolean } => {
        const shouldSwap =
            (interactingHandle === "min" && xPositionLeft > xPositionRight) ||
            (interactingHandle === "max" && xPositionRight < xPositionLeft);
        const oppositeHandle = interactingHandle === "min" ? "max" : "min";
        const handle = shouldSwap ? oppositeHandle : interactingHandle;
        const value = getValueFromXPos(interactingHandle === "min" ? xPositionLeft : xPositionRight);
        const swapValue = getValueFromXPos(handle === "min" ? xPositionLeft : xPositionRight);
        return {
            handle,
            value,
            swapValue,
            shouldSwap,
        };
    };

    const endInteraction = (interactingHandle: SliderButtonType | null): void => {
        // Only complete endInteraction when there user is no longer interacting with the screen.
        if (interactingHandle && !isInteracting) {
            // Snap handles to the correct step
            if (interactingHandle === "min") {
                const newPosition = getXPosFromValue(getValueFromXPos(xPositionLeft));
                if (newPosition !== xPositionLeft) setXPositionLeft(newPosition);
            } else if (interactingHandle === "max") {
                const newPosition = getXPosFromValue(getValueFromXPos(xPositionRight));
                if (newPosition !== xPositionRight) setXPositionRight(newPosition);
            }
            const { handle, value, shouldSwap, swapValue } = validateHandlePositions(interactingHandle);
            setValue(value, handle);
            if (shouldSwap) {
                setValue(swapValue, interactingHandle);
            }
            setIsSliding(null);
        }
    };

    return {
        getValueFromXPos,
        getOnMouseDown,
        getOnTouchStart,
        getOnKeyDown,
        xPositionLeft,
        xPositionRight,
    };
};

export default useButtonSlider;
