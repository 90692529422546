import { useDispatch } from "react-redux";
import { trackEvent } from "../../../../common-deprecated/utils/tracking";
import { setActiveFilters } from "../../../../used-stock-cars/car-filter/redux/actions/CarFiltersActions";
import type { CarFilterDispatchType } from "../../../../used-stock-cars/car-filter/redux/store";
import { useCarFilterSelector } from "../../../../used-stock-cars/car-filter/redux/store";
import type { CarFilterIdType } from "../../../../used-stock-cars/shared-logic/types/UscCommonTypes";
import { getAemTrackingDefaults } from "../../../../used-stock-cars/car-filter/utils/tracking";

const useFilterList = (): {
    activeFilterIds: CarFilterIdType[];
    dropdownClick: (filterId: CarFilterIdType) => () => void;
    activeFiltersKey: string;
} => {
    const dispatch = useDispatch<CarFilterDispatchType>();
    const context = useCarFilterSelector((state) => state.carFilters.currentFilter);
    const activeFilterIds = useCarFilterSelector((state) => state.carFilters.activeFilters);

    // Key is used as a memo argument to determine if the component needs rerendering.
    const activeFiltersKey = Object.keys(activeFilterIds).sort().join();

    // Add or remove the filter from the active filter state on click.
    const dropdownClick = (filterId: CarFilterIdType) => () => {
        const isActive = activeFilterIds.includes(filterId);
        if (isActive) {
            dispatch(setActiveFilters(activeFilterIds.filter((id) => id !== filterId)));
        } else {
            dispatch(setActiveFilters(activeFilterIds.concat([filterId])));
            trackEvent({
                ...getAemTrackingDefaults(context),
                action: "open-filter-advanced",
                label: filterId,
            });
        }
    };

    return { activeFilterIds, dropdownClick, activeFiltersKey };
};

export default useFilterList;
